import { Get, Post, uriName } from "../utilities/apiUtility";
export const SAVE_DESTINATION_SP_PART_LOCATION_ID =
    "SAVE_DESTINATION_SP_PART_LOCATION_ID";
export const SAVE_SOURCE_SP_PART_LOCATION_ID =
    "SAVE_SOURCE_SP_PART_LOCATION_ID";
export const SAVE_IS_COMING_IN =
    "SAVE_IS_COMING_IN";
export const SAVE_SUB_LOCATION_PARENT_PART_LOCATION_ID =
"SAVE_SUB_LOCATION_PARENT_PART_LOCATION_ID";

export const saveSpDestinationPartLocationIdReverse = spDestinationPartLocationIdReverse => ({
    type: SAVE_DESTINATION_SP_PART_LOCATION_ID,
    spDestinationPartLocationIdReverse
});

export const saveSpSourcePartLocationIdReverse = spSourcePartLocationIdReverse => ({
    type: SAVE_SOURCE_SP_PART_LOCATION_ID,
    spSourcePartLocationIdReverse
});

export const saveIsComingIn = isComingIn => ({
    type: SAVE_IS_COMING_IN,
    isComingIn
})

export const saveOnGoingToParentPartLocationIdReverse = subLocationParentPartLocationId => ({
    type: SAVE_SUB_LOCATION_PARENT_PART_LOCATION_ID,
    subLocationParentPartLocationId
})