import { combineReducers } from "redux";
import inventoryTransfer from "./reducers/inventoryTransferReducers";
import inventoryReverseTransfer from "./reducers/ReverseTransferReducers";
import error from "./reducers/errorReducers";
import previousTransfer from "./reducers/previousTransfersReducers";
import externalTransfer from "./reducers/ExternalTransfersReducers";
import viewExternalTransfer from "./reducers/viewExternalTransfersReducers";

const rootReducer = {
  error,
  previousTransfer,
  externalTransfer,
  inventoryTransfer,
  inventoryReverseTransfer,
  viewExternalTransfer
};

export default combineReducers({
  ...rootReducer
});
