import React, { memo } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
//import "react-table/react-table.css";
import "../../../../styles/_reactTable-overrides.scss";
import '../DashTable/DashTable.scss'

const DashTable = ({ reactTableDetails, resumeable, rowSelected }) => {
  let skuCount = 0;
  reactTableDetails.forEach(function (element) {
    skuCount += element.SKU.length;
  })
  const count = ((reactTableDetails.length) + skuCount) - 1;
  const columns = [
    {
      accessor: 'name',
      maxWidth: 300,
      style: {
        height: 5
      }
    }, {
      accessor: 'count',
      maxWidth: 300,
      style: {
        height: 5,
        textAlign: "right"
      }
    }
  ];

  const subColumns = [
    {
      expander: true,
      Expander: ({ isExpanded }) => 
      <div>
        {
          isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>
        }
      </div>,
      style: {
        cursor: "pointer",
        fontSize: 25,
        padding: "0",
        textAlign: "center",
        userSelect: "none",
        color: "green"
      }
    },
    {
      accessor: 'name',
      maxwidth: 300,
      style: {
        textAlign: "left",
        fontWeight: "bold"
      }
    }
  ];


  const subSubColumns = [
    {
      Header: "Serial Number",
      accessor: 'serialNumber'
    },
    {
      Header: "Transferred Location",
      accessor: 'location'
    },
    {
      Header: "Transfer Date",
      accessor: 'transferDate'
    }
  ];


  const clickButton = (function () {
    for (var i = 0; i <= count; i++) {
      document.getElementsByClassName('rt-expandable')[i].click()
    }
  });

  const subSubComponent = row => {
    return (
      <div style={{ height: '100%' }}>
        <ReactTable
          style={{overflow:'initial'}}
          data={row.original.serials}
          columns={subSubColumns}
          minRows={0}
          noDataText=""
          showPageSizeOptions={true}
          defaultPageSize={15}
          showPagination={false}
        />
      </div>
    );
  }

  const subComponent = row => {
    return (
      <div style={{ height: '100%' }}>
        <ReactTable
          style={{overflow:'initial'}}
          data={row.original.SKU}
          columns={subColumns}
          minRows={0}
          noDataText=""
          showPageSizeOptions={true}
          defaultPageSize={15}
          showPagination={false}
          SubComponent={subSubComponent}
        />
      </div>
    );
  };

  // const contentCount = {};
  // reactTableDetails.map((element, index) => {contentCount[index] = true});
  if (reactTableDetails.length > 0)
    setTimeout(() => clickButton(), 1000);
  return (
    <ReactTable
      style={{overflow:'initial'}}
      data={reactTableDetails}
      columns={columns}
      defaultPageSize={10}
      getTrProps={(state, rowInfo, column) => {
        return {
          style:
          {
            background: '#191970',
            color: 'white'
          }
        }
      }}
      minRows={0}
      noDataText=""
      showPageSizeOptions={true}
      showPagination={false}
      SubComponent={subComponent}
    />
  );
};

const mapStateToProps = ({ previousTransfer }) => {
  const { previousTransfersDetails, reactTableDetails } = previousTransfer;
  return {
    previousTransfersDetails,
    reactTableDetails
  };
};

export default connect(
  mapStateToProps,
  null
)(memo(DashTable));
