export const isEmpty = input => {
  if (typeof input === 'undefined' || input === 'null') {
    return true;
  }
  if (typeof input === 'function') {
    return false;
  }
  if (Array.isArray(input)) {
    return input.length === 0;
  }
  return !input || Object.keys(input).length === 0;
};

export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = decodeURIComponent(
    atob(base64Url)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(base64);
};

export const isEquivalent = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  return true;
};
