import React, { Component } from "react";
import Button from "../../Commons/Button/Button";
import { connect } from "react-redux";
import {
  startNewTransfer, setTransferCompleteToFalse
} from "../../../actions/inventoryTransferActions";
import { saveViewPreviousTransfers } from "../../../actions/previousTransferActions"
import { bindActionCreators } from "redux";
import TransferedTable from "../TransferedTable/TransferedTable";
import { userInfo } from "../../../utilities/apiUtility"
import "./TransferedMain.scss";

class TransferedMain extends Component {
  constructor(props){
    super(props)
  }

  render() {
    return (
      <div className="transfered-main_cntnr">
        <label className="transfer-main_hdr">Transfer complete</label>
        {/* <label>Inventory going out from FSL - New Jersey to Truck 3.</label> */}
        <TransferedTable />
        <Button text="START NEW TRANSFER" onClick={() => this.props.startNewTransfer()} />
        <Button text="View previous transfers" onClick={() => {
          this.props.saveViewPreviousTransfers(userInfo.serviceProviderId , userInfo.serviceProviderLocationId, this.props.setTransferCompleteToFalse);
          // this.props.setTransferCompleteToFalse();
        }}/>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startNewTransfer,
    saveViewPreviousTransfers,
    setTransferCompleteToFalse
  },
  dispatch
);

export default connect(
  null,
  mapDispatchToProps
)(TransferedMain);
