import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getServiceProviderLocations,
  getServiceProviderSubLocations,
  getServiceProviderSubLocationsForReverseTransfer,
  saveSpDestinationPartLocationId,
  saveSpSourcePartLocationId
} from "../../actions/inventoryTransferActions";
import { saveViewPreviousTransfers } from "../../actions/previousTransferActions";
import Header from "../Commons/Header/Header";
import DashMain from "./DashMain/DashMain";
import DashSide from "./DashSide/DashSide";
import RadioButton from "../Commons/RadioButton/RadioButton";
import { userInfo } from "../../utilities/apiUtility";
import "./Dashboard.scss";
import TransferDropDown from "../TransferInventory/TransferDropDown/TransferDropDown";
import {
  saveSpDestinationPartLocationIdReverse,
  saveSpSourcePartLocationIdReverse,
  saveIsComingIn,
  saveOnGoingToParentPartLocationIdReverse
} from "../../actions/ReverseTransferActions";
import DashSidebarButton from "./DashSidebarButton/DashSidebarButton";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceProviderLocationId: "",
      spDestinationPartLocationId: "",
      spSourcePartLocationId: "",
      spDestinationPartLocationIdReverse: "",
      spSourcePartLocationIdReverse: "",
      gointOut: false,
      comingIn: false,
      isComingIn: false
    };
    this.updateServiceProviderLocationId = this.updateServiceProviderLocationId.bind(
      this
    );
    this.updateSpDestinationPartLocationId = this.updateSpDestinationPartLocationId.bind(
      this
    );
    this.updateTransferDropDownValues = this.updateTransferDropDownValues.bind(
      this
    );
    this.updateSourcePartLocationIdReverse = this.updateSourcePartLocationIdReverse.bind(
      this
    );
    this.handleChecked = this.handleChecked.bind(this);
    this.updateSpDestinationPartLocationIdReverse = this.updateSpDestinationPartLocationIdReverse.bind(
      this
    );
  }

  updateSpDestinationPartLocationId(event) {
    this.setState(
      {
        spDestinationPartLocationId: event.target.value
      },
      () => {
        this.props.saveSpDestinationPartLocationId(
          this.state.spDestinationPartLocationId
        );
      }
    );
  }

  updateServiceProviderLocationId(event) {
    if (event.target.value !== "select") {
      const spSourcePartLocationId = this.props.serviceProviderLocationName.filter(
        val => {
          if (val.SERVICE_PROVIDER_LOCATION_ID === event.target.value) {
            console.info("selected " + val.SP_PART_LOCATION_ID);
            return val.SP_PART_LOCATION_ID;
          }
        }
      );

      this.setState(
        {
          serviceProviderLocationId: event.target.value
        },
        () => {
          this.props.getServiceProviderSubLocations(
            this.props.serviceProviderId,
            this.state.serviceProviderLocationId,
            spSourcePartLocationId
          );
        }
      );
    }
  }

  updateSpDestinationPartLocationIdReverse(event) {
    if (event.target.value !== "select") {
      console.log(this.state.spSourcePartLocationIdReverse);
      const spDestinationPartLocationIdReverse = this.props.serviceProviderLocationName.filter(
        val => {
          if (val.SERVICE_PROVIDER_LOCATION_ID === event.target.value) {
            console.info("selected " + val.SP_PART_LOCATION_ID);
            return val.SP_PART_LOCATION_ID;
          }
        }
      );
      this.props.saveSpDestinationPartLocationIdReverse(
        spDestinationPartLocationIdReverse[0].SP_PART_LOCATION_ID
      );
    }
  }

  updateSourcePartLocationIdReverse(event) {
    if (event.target.value !== "select") {
      this.setState(
        {
          spSourcePartLocationIdReverse: event.target.value
        },
        () => {
          this.props.saveSpSourcePartLocationIdReverse(
            this.state.spSourcePartLocationIdReverse
          );
        }
      );
      const onGoingToParentPartLocationIdReverse = this.props.serviceProviderSubLocationName.filter(
        val => {
          if (val.SP_PART_LOCATION_ID === event.target.value) {
            return val.PARENT_PART_LOCATION_ID;
          }
        }
      );
      this.props.saveOnGoingToParentPartLocationIdReverse(
        onGoingToParentPartLocationIdReverse[0].PARENT_PART_LOCATION_ID
      );
    }
  }

  updateTransferDropDownValues() {
    if (this.state.gointOut && this.state.gointOut === true) {
      this.props.getServiceProviderLocations(userInfo.serviceProviderId);
      this.props.saveIsComingIn(this.state.isComingIn);
    }
    if (this.state.comingIn && this.state.comingIn === true) {
      this.setState(
        {
          isComingIn: true
        },
        () => {
          this.props.saveIsComingIn(this.state.isComingIn);
        }
      );
      this.props.getServiceProviderSubLocationsForReverseTransfer(
        userInfo.serviceProviderId,
        userInfo.serviceProviderLocationId
      );
      this.props.getServiceProviderLocations(userInfo.serviceProviderId);
    }
  }

  handleChecked(event) {
    if (event.target.name === "rdogng") {
      this.setState(
        {
          gointOut: true,
          comingIn: false,
          isComingIn: false
        },
        () => this.updateTransferDropDownValues()
      );
    }
    if (event.target.name === "rdocmg") {
      this.setState(
        {
          comingIn: true,
          gointOut: false
        },
        () => this.updateTransferDropDownValues()
      );
    }
  }

  render() {
    return (
      <div className="dashboard">
        <DashSide label="Transfer locations">
          <>
            <label>Is the inventory going out or coming in at the FSL?</label>
            <div className="flex-cntnr">
              <RadioButton
                id="go_rd"
                name="rdogng"
                label="Going out"
                checkedValue={this.state.gointOut}
                onChangeValue={this.handleChecked}
              />
              <RadioButton
                id="ci_rd"
                name="rdocmg"
                label="Coming in"
                checkedValue={this.state.comingIn}
                onChangeValue={this.handleChecked}
              />
            </div>
            {this.state.isComingIn ? (
              <TransferDropDown
                isComingIn={this.state.isComingIn}
                onComingFromChange={this.updateSourcePartLocationIdReverse}
                onGoingToChange={this.updateSpDestinationPartLocationIdReverse}
              />
            ) : (
              <TransferDropDown
                isComingIn={this.state.isComingIn}
                onComingFromChange={this.updateServiceProviderLocationId}
                onGoingToChange={this.updateSpDestinationPartLocationId}
              />
            )}
          </>
          <div
            className=".dash-main-bttn-side"
            style={{
              position: "fixed",
              bottom: "117px",
              width: "auto",
              display: "inline-block",
              textAlign: "center"
            }}
          >
            <DashSidebarButton
              disable={false}
              title="View Previous Transfers"
              onClick={() =>
                this.props.saveViewPreviousTransfers(
                  userInfo.serviceProviderId,
                  userInfo.serviceProviderLocationId
                )
              }
            />
          </div>
        </DashSide>
        <DashMain />
      </div>
    );
  }
}

Dashboard.defaultProps = {};

Dashboard.propTypes = {};

const mapStateToProps = ({ inventoryTransfer }) => {
  const {
    serviceProviderId,
    serviceProviderLocationName,
    serviceProviderSubLocationName
  } = inventoryTransfer;
  return {
    serviceProviderId,
    serviceProviderLocationName,
    serviceProviderSubLocationName
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getServiceProviderLocations,
      getServiceProviderSubLocations,
      getServiceProviderSubLocationsForReverseTransfer,
      saveSpDestinationPartLocationIdReverse,
      saveSpSourcePartLocationIdReverse,
      saveOnGoingToParentPartLocationIdReverse,
      saveSpDestinationPartLocationId,
      saveSpSourcePartLocationId,
      saveIsComingIn,
      saveViewPreviousTransfers
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
