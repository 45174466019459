import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Table, Row,Select, Input, Button, DatePicker, Space, Typography } from 'antd';
import {getInitialState,getExternalTransfersForSelectedRange,getExternalTransfersForLastSevenDays,setTableData, gotoETnPerform, getRMANumberDetails} from "../../../../../actions/viewExternalTransferActions";
import {
  navigateExternalTransfers,
  startNewExternalTransfer,
  disableScan,
  sendShippingLabelRequestToSNSQueue,
  getShippingLabel,
  updateInventoryRtnId
} from "../../../../../actions/ExternalTransferActions";
const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography;

class DashMain extends React.Component {
  constructor(props) {
    super(props);
    const metaData = {
      INITIATED:{name:'Initiated',actions:[ { name: "View", id: "Vw" },{ name: "Print Packing List", id: "PackListPrnt" }],default:'PackListPrnt'},
      PKNG_LST_CREATED:{name:'Packing List Created',actions:[{ name: "View", id: "Vw" }, { name: "Print Packing List", id: "PackListPrnt" },{ name: "Request Shipping Label", id: "ReqShpngLblPrnt" }],default:'ReqShpngLblPrnt'},
      LABEL_REQUESTED:{name:'Label Requested',actions:[{ name: "View", id: "Vw" },{ name: "Print Packing List", id: "PackListPrnt" }],default:'Vw'},
      LABEL_GENERATED:{name:'Label Generated', actions: [{ name: "View", id: "Vw" }, { name: "Print Packing List", id: "PackListPrnt" },{ name: "Print Shipping Label", id: "ShpngLblPrnt" },{ name: "Complete", id: "com" }],default:'ShpngLblPrnt'},
      COMPLETED:{name:'Completed',actions: [{ name: "View", id: "Vw" }, { name: "Print Packing List", id: "PackListPrnt" },{ name: "Print Shipping Label", id: "ShpngLblPrnt" }],default:'Vw'},
      CLOSED:{name:'Closed',actions:[{ name: "View", id: "Vw" }],default:'Vw'}
    }
    const actionsfiltered = (status) => metaData[status]['actions'].map((statusInfo, key) => {
      return (
        <Option key={key} value={statusInfo.id}>
          {statusInfo.name}
        </Option>
      );
    });
    this.state = {
      extTransferActions: {},
      sevenDays:false,
      rangePicker:false,
      search:false,
      dateRange:[null,null],
      searchRma:'',
      columns :[
        {
          title: 'Date',
          dataIndex: 'date'
        },
        {
          title: 'Reference Number',
          dataIndex: 'rmaNumber'
        },
        {
          title: 'Status',
          dataIndex: 'status',
          render: text=>metaData[text]['name']
        },
        {
          title: 'Action',
          render: record => <Row><Space>
                            <Select style={{ width: 200 }} onChange={(value)=>{this.onActionSelect(record.inventoryRtnId,value)}} defaultValue={metaData[record.status].default} >{actionsfiltered(record.status)}</Select>
                            <Button onClick={()=>{this.onGotoExternalTransfer(record.inventoryRtnId,record.rmaNumber,record.selected,record.status)}}>Go</Button>
                            </Space></Row>
        }
      ]
    };
    
    this.on7daysSearch=this.on7daysSearch.bind(this);
    this.onRangeSearch=this.onRangeSearch.bind(this);
    this.onActionSelect = this.onActionSelect.bind(this);
    this.onGotoExternalTransfer =this.onGotoExternalTransfer.bind(this);
    this.startNewET = this.startNewET.bind(this);
    this.onRMASearch= this.onRMASearch.bind(this)
  }
  on7daysSearch(){
    this.setState({sevenDays:true,dateRange:[null,null],searchRma:''},()=>this.props.getExternalTransfersForLastSevenDays());
  }
  onRangeSearch(dates, dateStrings){
    this.setState({sevenDays:false,dateRange:dates, dateStrings:dateStrings,searchRma:''},
      ()=> 
      {
        console.log('callback function executed');
        if(this.state.dateRange && this.state.dateRange[0] &&this.state.dateRange[1]){this.props.getExternalTransfersForSelectedRange(this.state.dateStrings[0],this.state.dateStrings[1],null)
        }
      }
      );
  }

  onRMASearch(value){
    this.setState({sevenDays:false,dateRange:[null,null],searchRma:value},(e)=> {this.props.getRMANumberDetails(this.state.searchRma) });
  }
  onActionSelect(invid, option) {
    let data = this.props.tableData;
    console.log(`ActionChanged`)
    console.log(invid);
    console.log(option);
    data=data.map(item => {
      if (item.inventoryRtnId === invid) {
        if (option === 'PackListPrnt')
          item.selected = 'PRINT_PACKING_LIST';
        else if (option === 'ReqShpngLblPrnt')
          item.selected = 'REQUEST_LABEL';
        else if (option === 'Vw')
          item.selected = 'VIEW';
        else if (option === 'ShpngLblPrn')
          item.selected = 'PRINT_LABEL'
        else if (option === 'com')
          item.selected = 'COMPLETE'
        else { item.selected = 'VIEW'; }      
      }
      return item;
    })
    this.props.setTableData(data);
  }
  onGotoExternalTransfer(invid,rmaNumber,perform, status){
    this.props.gotoETnPerform(invid,rmaNumber, perform, status)
  }
  
  startNewET(){
    this.props.startNewExternalTransfer();
    this.props.getInitialState();
  }


  selectedInventoryIdDetails(inventoryRtnId) {
    this.props.navigateExternalTransfers(
      this.state.extTransferActions,
      inventoryRtnId
    );
    this.props.disableScan();
  }

  render() {
    return (
      <div style={{padding:'1rem 5rem'}}>
        <Row>
          <Space direction="vertical" style={{width:'100%'}}>
            <Title level={3}>Previous External Transfers</Title>
            <Table  loading = {this.props.tableLoadingOn}
              columns={this.state.columns}
              dataSource={this.props.tableData}
              bordered
              size="small"
              title={() => <Row><Space>
                <Button type={(this.state.sevenDays===true)?'primary':''} size="medium" onClick={()=>this.on7daysSearch()}>Last 7 days</Button>
                <RangePicker value={this.state.dateRange}   onCalendarChange={(dates, dateStrings)=>{this.onRangeSearch(dates,dateStrings)}} />
                <Search placeholder="Search RMA Number" value={this.state.searchRma} enterButton v onChange={(event)=>this.setState({searchRma:event.target.value})}  onSearch={value => this.onRMASearch(value)} />
              </Space></Row>}
              footer={() => <Row><Space>
                <Button type='primary' size="large" onClick={() => this.startNewET()}>START NEW TRANSFER</Button>
              </Space></Row>}
            />
          </Space>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ viewExternalTransfer, externalTransfer }) => {
  const { selectedAction, tableData, tableLoadingOn } = viewExternalTransfer;
  const { partDetailsExternalTransfers, serviceProviderIdExternalTransfers, inventoryRtnId } = externalTransfer;
  return {
    tableData,
    tableLoadingOn,
    selectedAction,
    partDetailsExternalTransfers,
    serviceProviderIdExternalTransfers,
    inventoryRtnId
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      navigateExternalTransfers,
      startNewExternalTransfer,
      disableScan,
      sendShippingLabelRequestToSNSQueue,
      getShippingLabel,
      getInitialState,
      updateInventoryRtnId,
      getExternalTransfersForSelectedRange,
      getExternalTransfersForLastSevenDays,
      setTableData,
      gotoETnPerform,
      getRMANumberDetails
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashMain);
