import {
  SHOW_ERROR,
  HIDE_ERROR,
  HIDE_ERROR_ET,
  SHOW_ERROR_ET,
  SHOW_GROUP_ERRORS
} from "../actions/errorActions";

const initialState = {
  showError: false,
  errorMessage: "",
  showErrorET: false,
  errorMessageET: "",
  showErrorMessages: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ERROR:
      return {
        ...state,
        showError: true,
        errorMessage: action.payload
      };
    case SHOW_GROUP_ERRORS:
      return {
        ...state,
        showErrorMessages: action.payload
      };
    case SHOW_ERROR_ET:
      return {
        ...state,
        showErrorET: true,
        errorMessageET: action.payload
      };
    case HIDE_ERROR:
      return { ...initialState };
    case HIDE_ERROR_ET:
      return { ...initialState };
    default:
      return state;
  }
};
