import React, { Component } from "react";
// import TransferedMain from "./TransferedMain/TransferedMain";
import "./PreviousTransfers.scss";
import Dashboard from "../PreviousTransfers/Dashboard/Dashboard";

class PreviousTransfers extends Component {
  render() {
    return (
      <div className="completetransfer">
        <Dashboard/>
      </div>
    );
  }
}

export default PreviousTransfers;