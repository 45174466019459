import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import { deletePartSerial } from "../../../../../actions/ExternalTransferActions";
import { Table, Row, Divider, Steps } from 'antd';
const {Step}= Steps;

const DashTable = ({
  partDetailsExternalTransfers,
  inventoryRtnId,
  resumeable,
  rowSelected,
  deletePartSerial,
  rmaAction,
  showDeleteButton
}) => {
  const columns = [
    {
      Header: "Part Number",
      accessor: "partNumber"
    },
    {
      Header: "Serial Number",
      accessor: "serialNumber"
    },
    {
      Header: "Delete",
      id: "delete",
      accessor: str => "delete",
      show: showDeleteButton,
      Cell: ({ cell, row }) => (
        <span
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline"
          }}
          onClick={() =>{
            deletePartSerial(row, partDetailsExternalTransfers, inventoryRtnId)}
          }
        >
          Delete
        </span>
      )
    }
  ];
  const columns2 = (rmaAction && rmaAction==='INITIATED')?[
    {title: "Part Number", dataIndex: "partNumber"},
    {title: "Serial Number", dataIndex: "serialNumber"},
    {title: "Delete",render: record=><span><a onClick={()=> { console.log(`part delete started`); deletePartSerial(record.serialNumber,partDetailsExternalTransfers, inventoryRtnId)}}>Delete</a></span>}]
    :[{ title: "Part Number", dataIndex: "partNumber"},{title: "Serial Number", dataIndex: "serialNumber"}] ;

  return (
    <>
    <Table style={{width:'100%'}}
    pagination={false}
    scroll={{ y: 200 }}
    columns={columns2}
    dataSource={partDetailsExternalTransfers}
    bordered
    size='middle'
    footer={()=> <Row style={{padding:'0rem'}}><Steps
        current='4'
        type="navigation"
        size="small"
        className="site-navigation-steps" >
        <Step status={( rmaAction && ["LABEL_REQUESTED", "LABEL_GENERATED", "INITIATED", "PKNG_LST_CREATED", "COMPLETED"].includes( rmaAction)) ? "finish" : "wait"} title="Started" />
        <Step status={( rmaAction && ["LABEL_REQUESTED", "LABEL_GENERATED", "PKNG_LST_CREATED", "COMPLETED"].includes( rmaAction)) ? "finish" : "wait"} title="Packing List" />
        <Step status={( rmaAction && ["LABEL_REQUESTED", "LABEL_GENERATED", "COMPLETED"].includes( rmaAction)) ? "finish" : "wait"} title="Label Requested" />
        <Step status={( rmaAction && ["LABEL_GENERATED", "COMPLETED"].includes( rmaAction)) ? "finish" : "wait"} title="Label Generated" />
        <Step status={( rmaAction && ["COMPLETED"].includes( rmaAction)) ? "finish" : "wait"} title="Completed" />
      </Steps>
    </Row>}
    />
    </>
  );
};

const mapStateToProps = ({ externalTransfer, viewExternalTransfer }) => {
  const {
    partDetailsExternalTransfers,
    inventoryRtnId,
    showDeleteButton,
    viewExternalTransfers,
    rmaAction
  } = externalTransfer;
  return {
    partDetailsExternalTransfers,
    inventoryRtnId,
    showDeleteButton,
    viewExternalTransfers,
    rmaAction
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deletePartSerial
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashTable);
