import React, { memo } from "react";
import "./DashSide.scss";

const DashSide = ({ children, label }) => (
  <aside className="dash-side">
    <label className="dash-search-lbl">{label}</label>
    <div className="dash-side-cntnr">{children}</div>
  </aside>
);

export default memo(DashSide);