import {
    SAVE_VIEW_PREVIOUS_TRANSFERS,
    SET_SHOW_CALENDER_TO_TRUE,
    SAVE_SP_PART_LOCATION_ID,
    SAVE_IS_COMING_IN_FOR_PREVIOUS_TRANSFERS,
    SAVE_APPLIED_DATES,
    SAVE_PREVIOUS_TRANSFERS_DETAILS,
    SAVE_STRUCTURED_DATA_TO_POPULATE_DATA_IN_REACT_TABLE,
    SET_VIEW_TRANSFERS_TO_FALSE,
    CLEAN_REACT_TABLE_DATA,
    EMPTY_FROM_AND_TO_DATES_AFTER_RETRIEVING_DATA
} from "../actions/previousTransferActions";

const initialState = {
    viewPreviousTransfers: false,
    currentView: null,
    showCalender: false,
    partDetails: [],
    spPartLocationId: null,
    previousTransfersIsComingIn: null,
    fromDate: null,
    toDate: null,
    previousTransfersDetails: [],
    reactTableDetails: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SAVE_VIEW_PREVIOUS_TRANSFERS:
            return {
                ...state,
                viewPreviousTransfers: true
            };
        case SET_VIEW_TRANSFERS_TO_FALSE:
            return {
                ...state,
                viewPreviousTransfers: false,
                reactTableDetails: [],
                previousTransfersIsComingIn: null
            };
        case CLEAN_REACT_TABLE_DATA:
            return {
                ...state,
                reactTableDetails: []
            };
        case SAVE_PREVIOUS_TRANSFERS_DETAILS:
        console.log(action.previousTransfers);
            return {
                ...state,
                previousTransfersDetails: action.previousTransfers
            };
        case EMPTY_FROM_AND_TO_DATES_AFTER_RETRIEVING_DATA:
            return {
                ...state,
                fromDate: null,
                toDate: null
            }
        case SAVE_IS_COMING_IN_FOR_PREVIOUS_TRANSFERS:
            console.log(action.isComingIn);
            console.log(action.currentView);
            return {
                ...state,
                previousTransfersIsComingIn: action.isComingIn ? true : false,
                currentView: action.currentView,
                previousTransfersDetails: [],
                reactTableDetails: [],
                fromDate: null,
                toDate: null
            }
        case SAVE_STRUCTURED_DATA_TO_POPULATE_DATA_IN_REACT_TABLE:
            return {
                ...state,
                reactTableDetails: action.result
            }
        case SAVE_APPLIED_DATES:
            console.log(action.from);
            console.log(action.to);
            return {
                ...state,
                fromDate: action.from,
                toDate: action.to
            }
        case SAVE_SP_PART_LOCATION_ID:
            console.log(action.spPartLocationId);
            return {
                ...state,
                spPartLocationId: action.spPartLocationId
            }
        case SET_SHOW_CALENDER_TO_TRUE:
            console.log(state.showCalender);
            return {
                ...state,
                showCalender: true,
                reactTableDetails: []
            };
        default:
            return state;
    }
}