import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {getServiceProviderLocations,getServiceProviderSubLocations,getPartSerialNumberDetails,movePartLocation,saveSpDestinationPartLocationId} from "../../actions/inventoryTransferActions";
import { updateCurrentSession } from "../../utilities/sessionUtility";
import { userInfo } from "../../utilities/apiUtility";
import { isEmpty } from "../../utilities/genericUtility";
import CompleteTransfer from "../CompleteTransfer/CompleteTransfer";
import Dashboard from "../Dashboard/Dashboard";
import SessionOut from "../SessionOut/SessionOut";
import "./App.css";
import PreviousTransfers from "../PreviousTransfers/PreviousTransfers";
import ExternalTransfers from "../Returns/ExternalTransfers/ExternalTransfers";
import ViewExternalTransfers from "../Returns/ViewExternalTransfers/ViewExternalTransfers";
import InventoryMenu from "../Menu/InventoryMenu";
import { Layout, Spin  } from 'antd';
const { Header, Content} = Layout;

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      sessionExpired: false
    };
    const url = new URL(window.location.href);
    userInfo.token = url.searchParams.get("t");
    userInfo.refreshToken = url.searchParams.get("r");
    userInfo.serviceProviderId = url.searchParams.get("spi");
    userInfo.serviceProviderLocationId = url.searchParams.get("spl");
    userInfo.userName = url.searchParams.get("u");
    console.info(userInfo.serviceProviderId);
    console.info(userInfo.serviceProviderLocationId);
    console.info(userInfo.userName);
    if (isEmpty(userInfo.token))
      this.state = {
        sessionExpired: true
      };
    updateCurrentSession(() => this.setSessionExpired());
  }

  // componentDidMount() {
  //   this.props.getServiceProviderLocations(userInfo.serviceProviderId);
  // }

  setSessionExpired = () => {
    this.setState({
      sessionExpired: true
    });
  };

  closeWindow() {
    // ==UserScript==
    // @grant       GM_addStyle
    // ==/UserScript==
    window.open(window.location, "_self").close();
  }

  render() {
    console.log(this.props.transferComplete, this.state.sessionExpired, this.props.enableExternalTransfers)
    return (
      <>
        {this.state.sessionExpired ? (
          <SessionOut closeWindow={() => this.closeWindow()} />
        ) :
        <div >
          <Layout className="layout" style={{height:'100%',backgroundColor:`#ededed`}}>
            <Header style={{height:'80px',borderBottom: '1px solid',borderColor:`#d9d9d9`, backgroundColor:`#ededed`}}>
              <InventoryMenu />
            </Header>
              <Content >
                {this.props.isLoading ? <div key="spinner" style={{ 'text-align': 'center', paddingTop: '200px' }}><Spin size="large" /></div> :
                  !this.props.transferComplete ?
                    (this.props.enableExternalTransfers ?
                      (this.props.viewExternalTransfers ?
                        <ViewExternalTransfers />
                        : <ExternalTransfers />)
                      : (this.props.viewPreviousTransfers ?
                        <PreviousTransfers />
                        : <Dashboard />))
                    : (<CompleteTransfer />
                    )}
              </Content>
          </Layout>
          </div>  }
      </>
    )
  }
}

App.propTypes = {
  serviceProviderId: PropTypes.string,
  spSourcePartLocationId: PropTypes.string,
  getServiceProviderLocations: PropTypes.func,
  getServiceProviderSubLocations: PropTypes.func,
  getPartSerialNumberDetails: PropTypes.func,
  movePartLocation: PropTypes.func,
  saveSpDestinationPartLocationId: PropTypes.func,
  partSerialNumber: PropTypes.string,
  partNumber: PropTypes.string,
  partSku: PropTypes.string,
  inventoryLocationIdPrevious: PropTypes.string,
  inventoryLocationId: PropTypes.string
};

const mapStateToProps = ({ inventoryTransfer, previousTransfer, externalTransfer }) => {
  const { transferComplete } = inventoryTransfer;
  const { viewPreviousTransfers } = previousTransfer;
  const { enableExternalTransfers, setExternalTransferComplete, viewExternalTransfers, isLoading } = externalTransfer;
  return {
    transferComplete,
    viewPreviousTransfers,
    enableExternalTransfers,
    setExternalTransferComplete,
    viewExternalTransfers,
    isLoading
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getServiceProviderLocations,
      getServiceProviderSubLocations,
      getPartSerialNumberDetails,
      movePartLocation,
      saveSpDestinationPartLocationId
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
