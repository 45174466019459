import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import "./DashTableFilter.scss"
import DatePicker from '../DatePicker/DatePicker';
import Button from "../../../Commons/Button/Button";
import PreviousTransfersModal from "../../PreviousTransfersModal/PreviousTransfersModal";
import { bindActionCreators } from "redux";
import {
  setShowCalenderToTrue, getPreviousTransfersForLastSevenDays, getPreviousTransfersForSelectedRange
} from "../../../../actions/previousTransferActions";
import { stat } from 'fs';


const DashFilterTab = ({ name, onClick, selected }) => {
  const classes = classNames('dash-table_filter__item', {
    'dash-table_filter__item-selected': selected
  });
  return (
    <div className={classes} onClick={onClick}>
      {name}
    </div>
  );
};

class DashTableFilter extends Component {
  constructor() {
    super();
    this.closeWindow = this.closeWindow.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    console.log(state.currentView);
    console.log(props.currentView);
    if (props.currentView !== state.currentView) {
      return { selectedId: 'NONE', currentView: props.currentView, needTitleForModal: false }
    }
  }

  state = {
    selectedId: 'NONE',
    needDatePicker: false,
    needTitleForModal: false
  };

  onSelect = clientId => {
    this.setState({
      selectedId: clientId
    });
    if (clientId === 'SELECT') {
      this.setState({
        needDatePicker: true
      })
      this.props.setShowCalenderToTrue();
    }
    else {
      this.setState({
        needDatePicker: false
      })
      this.props.getPreviousTransfersForLastSevenDays(this.props.spPartLocationId, this.props.previousTransfersIsComingIn);
    }
  }

  closeModal(){
    this.setState({
      needDatePicker: false,
      needTitleForModal: false
    })
  }

  closeWindow() {
    if (this.props.fromDate !== null && this.props.toDate !== null) {
      this.setState({
        needTitleForModal: false
      })
      this.setState(
        {
          needDatePicker: false
        }, () => this.props.getPreviousTransfersForSelectedRange(this.props.spPartLocationId, this.props.previousTransfersIsComingIn, this.props.fromDate, this.props.toDate)
      )
    } else {
      this.setState(
        {
          needTitleForModal: true
        }
      )
    }
  }

  render() {
    return (
      <div className="dash-table-filter__container">
        {this.state.needDatePicker ?
          <PreviousTransfersModal
            title={this.state.needTitleForModal ? "Please select dates to proceed" : ""}
            message={<DatePicker />}
            closeModal={this.closeModal}
            buttons={<Button text="Apply" onClick={this.closeWindow} />}
          />
          : ""}
        <DashFilterTab
          key={'ALL'}
          name="Last 7 days"
          onClick={() => this.onSelect('ALL')}
          selected={this.state.selectedId === 'ALL'}
        />
        <DashFilterTab
          key={'SELECT'}
          name="Select Date Range"
          onClick={() => this.onSelect('SELECT')}
          selected={this.state.selectedId === 'SELECT'}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ previousTransfer }) => {
  const { spPartLocationId, previousTransfersIsComingIn, currentView, fromDate, toDate } = previousTransfer;
  return {
    spPartLocationId,
    previousTransfersIsComingIn,
    currentView,
    fromDate,
    toDate
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setShowCalenderToTrue,
      getPreviousTransfersForSelectedRange,
      getPreviousTransfersForLastSevenDays
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashTableFilter);
