import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import BarcodeIcon from "./icon-barcode.svg";
import errorSound from "./errorSevere-buzzer.mp3";
import "./PrimaryInput.scss";

class PrimaryInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      placeholder: this.props.placeholder,
      valid: false,
      value: ""
    };
    this.validator = new SimpleReactValidator({
      validators: {
        SerialNumber: {
          message: "Invalid Serial Number. Please try again!",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z0-9]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          required: true
        }
      }
    });
    this.handleChange = this.handleChange.bind(this);
    this.errorSound = new Audio(errorSound);
    this.setToOriginal = this.setToOriginal.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value.toUpperCase() });
  }

  setToOriginal() {
    this.setState({
      error: false,
      placeholder: this.props.placeholder,
      valid: false,
      value: ""
    });
  }

  componentDidUpdate() {
    if (this.props.showError && !this.state.error) {
      this.setState({
        error: true,
        valid: false,
        value: "",
        placeholder: this.props.errorMessage
      });
      this.errorSound.play();
      setTimeout(
        function() {
          this.setToOriginal();
        }.bind(this),
        1000
      );
    }
    if (this.props.showSuccess && !this.state.valid) {
      this.setState({
        error: false,
        valid: true,
        value: "",
        placeholder: this.props.successMessage
      });
      setTimeout(
        function() {
          this.setToOriginal();
        }.bind(this),
        1000
      );
    }
    if (this.props.showSuccessET && !this.state.valid) {
      this.setState({
        error: false,
        valid: true,
        value: "",
        placeholder: this.props.successMessageET
      });
      setTimeout(
        function() {
          this.setToOriginal();
        }.bind(this),
        1000
      );
    }
  }

  submitForm = e => {
    if (this.validator.allValid()) {
      if (this.props.onSubmit) {
        this.props.onSubmit(this.state.value);
      }
    } else {
      this.setState({
        error: true,
        placeholder: this.validator.getErrorMessages()[this.props.title],
        value: ""
      });
      this.errorSound.play();
      this.forceUpdate();
      setTimeout(
        function() {
          this.setToOriginal();
        }.bind(this),
        1000
      );
    }
    e.preventDefault();
  };

  render() {
    return (
      <form onSubmit={this.submitForm}>
        <div
          className={
            "workflow-primary-field " +
            (this.state.error ? "workflow-primary-field--error" : "") +
            (this.state.valid ? "workflow-primary-field--valid" : "")
          }
        >
          <label
            htmlFor="primary-field"
            className="workflow-primary-field__label"
          />
          <div className="workflow-primary-field__input-group ">
            <input
              type="text"
              value={this.state.value}
              onChange={this.handleChange}
              className="workflow-primary-field__input"
              id="primary-field"
              placeholder={this.state.placeholder}
              autoFocus
              autoComplete="off"
              disabled={this.props.enableExternalTransfers ? ((this.props.rmaAction && this.props.rmaAction==='INITIATED') ? false : true ) : false}
            />
            {/*Validation*/}
            {this.validator.message(
              this.props.title,
              this.state.value,
              this.props.rules
            )}
            <button
              type="submit"
              className="workflow-primary-field__btn js-scan-button"
              disabled={this.props.enableExternalTransfers ? ((this.props.rmaAction && this.props.rmaAction==='INITIATED') ?false : true) : false}
            >
              <img
                src={BarcodeIcon}
                className="workflow-primary-field__btn__icon"
                alt=""
                height="25"
              />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ error, inventoryTransfer, externalTransfer }) => {
  const { errorMessage, showError } = error;
  const { successMessage, showSuccess } = inventoryTransfer;
  const { showSuccessET, successMessageET, rmaAction, enableExternalTransfers } = externalTransfer;
  return {
    errorMessage,
    showError,
    successMessage,
    showSuccess,
    showSuccessET,
    successMessageET,
    rmaAction,
    enableExternalTransfers
  };
};

export default connect(mapStateToProps, null)(PrimaryInput);
