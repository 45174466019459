import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import logo from "../Commons/Header/asurion_logo_black_rgb.svg";
import { Menu, Button, Row, Space} from 'antd';
import {navigateToExternalTransfers } from "../../actions/ExternalTransferActions";

class InventoryMenu extends React.Component {

    constructor() {
        super();
        this.state = {
          menus :[
            {key:'fsl',name:'fsl',value:'FSL/Truck'},
            {key:'rma',name:'rma',value:'External Transfer'}
          ],
          active:['fsl']
        };
        this.onSelect = this.onSelect.bind(this);
    }
    onSelect(selected){
      this.setState(
        {
          active:[selected]
        },
        () => {
          this.props.navigateToExternalTransfers(
            this.state.active[0]
          );
        }
      )
    }


render() {
  return (
    <>
      <Row>
        <Space>
          <div className="logo"  >
            <img src={logo} style={{height:'80px'}} />
           </div> 
           <div style={{width:'50px'}}  >
           </div> 
           <div key="menuContainer" >
           <Space>
           {this.state.menus.map((menu) => (
             <Button  key={menu.key} type={this.state.active[0]===menu.key?"primary":"ghost"}  size="large" onClick={() => this.onSelect(`${menu.key}`)} style={{borderRadius:'3px'}}>
               {menu.value}
             </Button>
           ))}
           </Space>
           </div> 
        </Space>
      </Row>
    </>
  );
}
}

const mapStateToProps = ({ externalTransfer }) => {
    const {  } = externalTransfer;
    return {
    };
  };
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        navigateToExternalTransfers
      },
      dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps    
)(InventoryMenu);