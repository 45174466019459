import React, { memo } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './DatePicker.scss';
import { saveAppliedDates } from '../../../../actions/previousTransferActions'

class DatePicker extends React.Component {

    static defaultProps = {
        numberOfMonths: 2
    }

    constructor(props) {
        super(props);
        console.log(this.props.showCalendar);
        this.state = this.getInitialState();
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
    }

    getInitialState() {
        return {
            showCalendar: false,
            from: undefined,
            to: undefined
        };
    }

    handleDayClick(day) {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(
            {
               from: range.from,
               to: range.to
            },() => this.props.saveAppliedDates(this.state.from,this.state.to)
        )
    }

    handleResetClick() {
        this.setState(this.getInitialState());
    }

    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        return (
            <div className="RangeExample">
                {/* <p onClick={() => this.setState({
                    showCalendar: !this.state.showCalendar
                })}>
                    {!from && !to && 'Please select the first day'}
                    {from && !to && 'Please select the last day'}
                    {from && to &&
                        `Selected from ${from.toLocaleDateString()} to
                ${to.toLocaleDateString()}`}{' '}
                    {from && to && (
                        <button className="link" onClick={this.handleResetClick}>
                            Reset
            </button>
                    )}
                </p> */}
                {this.props.showCalender ? <DayPicker
                    className="Selectable"
                    numberOfMonths={this.props.numberOfMonths}
                    selectedDays={[from, { from, to }]}
                    modifiers={modifiers}
                    onDayClick={this.handleDayClick}
                /> : null}

                <style>
                    {`
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}
                </style>
            </div>
        )
    }
}

const mapStateToProps = ({ previousTransfer }) => {
    const { showCalender, viewPreviousTransfers } = previousTransfer;
    return {
        showCalender,
        viewPreviousTransfers
    };
  };

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            saveAppliedDates
        },
        dispatch
    );  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(memo(DatePicker));