import { Get, Post, uriName } from "../utilities/apiUtility";
import { hasError, showErrorMessage } from './errorActions';
import { isEmpty } from '../utilities/genericUtility';
import PreviousTransfers from "../components/PreviousTransfers/PreviousTransfers";
import { async } from "q";
export const SAVE_VIEW_PREVIOUS_TRANSFERS = "SAVE_VIEW_PREVIOUS_TRANSFERS";
export const SET_SHOW_CALENDER_TO_TRUE = "SET_SHOW_CALENDER_TO_TRUE";
export const GET_PREVIOUS_TRANSFERS = "GET_PREVIOUS_TRANSFERS";
export const SAVE_SP_PART_LOCATION_ID = "SAVE_SP_PART_LOCATION_ID";
export const SAVE_IS_COMING_IN_FOR_PREVIOUS_TRANSFERS = "SAVE_IS_COMING_IN_FOR_PREVIOUS_TRANSFERS";
export const SAVE_APPLIED_DATES = "SAVE_APPLIED_DATES";
export const SAVE_PREVIOUS_TRANSFERS_DETAILS = "SAVE_PREVIOUS_TRANSFERS_DETAILS";
export const SAVE_STRUCTURED_DATA_TO_POPULATE_DATA_IN_REACT_TABLE = "SAVE_STRUCTURED_DATA_TO_POPULATE_DATA_IN_REACT_TABLE";
export const SET_VIEW_TRANSFERS_TO_FALSE = "SET_VIEW_TRANSFERS_TO_FALSE";
export const CLEAN_REACT_TABLE_DATA = "CLEAN_REACT_TABLE_DATA";
export const EMPTY_FROM_AND_TO_DATES_AFTER_RETRIEVING_DATA = "EMPTY_FROM_AND_TO_DATES_AFTER_RETRIEVING_DATA";

export const saveViewPreviousTransfers = (serviceProviderId, serviceProviderLocationId, callbackdispatcher) => async dispatch => {
  try {
    let spPartLocationId;
    const isViewTransfersEnabled = true;
    const res = await Get(uriName, `/v1/gateway/get/${serviceProviderId}?serviceProviderLocationId=${serviceProviderLocationId}&isViewTransfersEnabled=${isViewTransfersEnabled}&serviceProviderId=${serviceProviderId}`);
    if (hasError(res)) {
      showErrorMessage('Unable to get the data from the server.');
    } else if (res && !isEmpty(res)) {
      spPartLocationId = res.spPartLocationId;
      dispatch(savePreviousTransfersSpPartLocationId(spPartLocationId));
      dispatch(enableViewPreviousTransfers());
      callbackdispatcher();
    }
  } catch (err) {
    console.log(err);
  }
};

export const getPreviousTransfersForLastSevenDays = (spPartLocationId, previousTransfersIsComingIn) => async dispatch => {
  try {
    const sevenDays = true;
    const postData = {
      sevenDays: sevenDays,
      spPartLocationId: spPartLocationId,
      fromDate: "",
      toDate: "",
      isComingIn: previousTransfersIsComingIn
    };
    console.log(postData);
    dispatch(cleanReactTableData());
    const res = await Post(uriName, `/v1/gateway/viewTransfers`, postData);
    if (hasError(res)) {
      showErrorMessage('Unable to get the data from server');
    } else if (res && !isEmpty(res)) {
      dispatch(structurePreviousTransfersDataInJsonFormat(res));
    }
  } catch (error) {
    console.log(error);
  }
}

export const getPreviousTransfersForSelectedRange = (spPartLocationId, previousTransfersIsComingIn, fromDate, toDate) => async dispatch => {
  try {
    if(toDate === null || toDate === undefined){
      var toTime = "23:59:59";
      var toDateInside = new Date(fromDate);
      var toDateInsideYear = toDateInside.getFullYear();
      var toDateInsideMonth = toDateInside.getMonth() + 1;
      var formattedToDate = toDateInside.getDate();
      var toDateFormattedInside = toDateInsideMonth + "/" + formattedToDate + "/" + toDateInsideYear + " " + toTime;
      console.log(toDateFormattedInside);
      console.log('you coded correct')
    } else {
      var toTime = "23:59:59";
      var toDateInside = new Date(toDate);
      var toDateInsideYear = toDateInside.getFullYear();
      var toDateInsideMonth = toDateInside.getMonth() + 1;
      var formattedToDate = toDateInside.getDate();
      var toDateFormattedInside = toDateInsideMonth + "/" + formattedToDate + "/" + toDateInsideYear + " " + toTime;
      console.log(toDateFormattedInside);
    }
    var fromTime = "00:00:00";
    var fromDateInside = new Date(fromDate);
    var fromDateInsideYear = fromDateInside.getFullYear();
    var fromDateInsideMonth = fromDateInside.getMonth() + 1;
    var formattedFromDate = fromDateInside.getDate();
    var fromDateFormattedInside = fromDateInsideMonth + "/" + formattedFromDate + "/" + fromDateInsideYear + " " + fromTime;
    console.log(fromDateFormattedInside);
    const sevenDays = false;
    const postData = {
      sevenDays: sevenDays,
      spPartLocationId: spPartLocationId,
      fromDate: fromDateFormattedInside,
      toDate: toDateFormattedInside,
      isComingIn: previousTransfersIsComingIn
    };
    console.log(postData);
    const res = await Post(uriName, `/v1/gateway/viewTransfers`, postData);
    if (hasError(res)) {
      showErrorMessage('Unable to get the data from server');
      dispatch(emptyFromAndToDatesAfterRetrievingAppropriateData());
    } else if (res && !isEmpty(res)) {
      dispatch(structurePreviousTransfersDataInJsonFormat(res));
      dispatch(emptyFromAndToDatesAfterRetrievingAppropriateData());
    }
  } catch (error) {
    console.log(error);
    dispatch(emptyFromAndToDatesAfterRetrievingAppropriateData());
  }
}

export const structurePreviousTransfersDataInJsonFormat = (res) => async dispatch => {
  let previousTransfers = [];
  let grouped = {};
  previousTransfers = res.previousTransfers;
  previousTransfers.forEach(function (element) {
    grouped[element.DESCRIPTION] = grouped[element.DESCRIPTION] || [];
    grouped[element.DESCRIPTION].push({ serialNumber: element.PART_SERIAL_NUMBER, TransferDate: element.PROCESSED_TIMESTAMP, Location: element.SP_PART_LOCATION_ID_TO, Source: element.DESCRIPTION, PartSKU: element.PART_NUMBER });
  });

  let groupedArray = [];
  groupedArray = Object.values(grouped);
  let jsonArray = [];
  for (var outerIndex = 0; outerIndex < groupedArray.length; outerIndex++) {
    let object = {};
    object.SKU = [];
    let count = 0;
    let element = groupedArray[outerIndex];
    let skuGrouped = {};
    element.forEach(function(item){
      object.name = item.Source;
      skuGrouped[item.PartSKU] = skuGrouped[item.PartSKU] || [];
      skuGrouped[item.PartSKU].push({ serialNumber: item.serialNumber, TransferDate: item.TransferDate, Location: item.Location, Source: item.Source, PartSKU: item.PartSKU })
    })

    let skuGroupedArray = [];
    skuGroupedArray = Object.values(skuGrouped);
    console.log(skuGroupedArray);
    for( var innerIndex = 0; innerIndex < skuGroupedArray.length; innerIndex++){
      let skuObject = {};
      skuObject.serials = [];
      let skuArray = skuGroupedArray[innerIndex];
      skuArray.forEach(function(sku){
        skuObject.name = sku.PartSKU;
        let insideObject = {};
        count ++;
        insideObject.serialNumber = sku.serialNumber;
        var formattedDate = new Date(sku.TransferDate);
        var finalFormattedDate = (formattedDate.getMonth() + 1) + "/" + formattedDate.getDate() + "/" + formattedDate.getFullYear();
        insideObject.transferDate = finalFormattedDate;
        insideObject.location = sku.Location;
        skuObject.serials.push(insideObject);
      })
      object.SKU.push(skuObject);
    }
    object.count = ' Total Transfers: ' + count;
    jsonArray.push(object)
  };
  console.log(jsonArray);
  dispatch(saveStructuredJSONArrayToPopulateDataInReactTable(jsonArray));
}

export const saveStructuredJSONArrayToPopulateDataInReactTable = (result) => ({
  type: SAVE_STRUCTURED_DATA_TO_POPULATE_DATA_IN_REACT_TABLE,
  result
});

export const emptyFromAndToDatesAfterRetrievingAppropriateData = () => ({
  type: EMPTY_FROM_AND_TO_DATES_AFTER_RETRIEVING_DATA
})

export const saveIsComingInForPreviousTransfers = (isComingIn, currentView) => ({
  type: SAVE_IS_COMING_IN_FOR_PREVIOUS_TRANSFERS,
  isComingIn,
  currentView
});

export const savePreviousTransfersDetails = previousTransfers => ({
  type: SAVE_PREVIOUS_TRANSFERS_DETAILS,
  previousTransfers
});

export const saveAppliedDates = (from, to) => ({
  type: SAVE_APPLIED_DATES,
  from,
  to
})

export const cleanReactTableData = () => ({
    type: CLEAN_REACT_TABLE_DATA
})

export const setShowCalenderToTrue = () => ({
  type: SET_SHOW_CALENDER_TO_TRUE
});

export const savePreviousTransfersSpPartLocationId = spPartLocationId => ({
  type: SAVE_SP_PART_LOCATION_ID,
  spPartLocationId
});

export const enableViewPreviousTransfers = () => ({
  type: SAVE_VIEW_PREVIOUS_TRANSFERS
});

export const disableViewPreviousTransfers = () => ({
  type: SET_VIEW_TRANSFERS_TO_FALSE
});

export const getPreviousTransfers = () => ({
  type: GET_PREVIOUS_TRANSFERS
});