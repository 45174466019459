import React, { memo } from "react";
import { connect } from "react-redux";
import "./DashSearch.scss";
import { bindActionCreators } from "redux";
import {
  getPartSerialNumberDetails
} from "../../../actions/inventoryTransferActions";
import PrimaryInput from "../../Commons/PrimaryInput/PrimaryInput";

const DashSearch = props => (
  <div className="dash-search-container">
    <label className="dash-search-lbl">
      What inventory would you like to use?
    </label>
    <div className="dash-search">
      <label htmlFor="search" className="dash-search__label">
        Search orders
      </label>
      <PrimaryInput
        nextStep={() => console.info("Scanning")}
        onSubmit={serialNumber => props.getPartSerialNumberDetails(props.partDetails,props.serviceProviderId, props.serviceProviderLocationId, serialNumber, props.isComingIn)}
        placeholder="Scan or enter serial number"
        rules='required|SerialNumber'
        title="Serial Number"
        type="Serial number"
      />
    </div>
  </div>
);


const mapStateToProps = ({ inventoryTransfer, inventoryReverseTransfer }) => {
  const {
    serviceProviderId,
    serviceProviderLocationId,
    partDetails
  } = inventoryTransfer;
  const { isComingIn } = inventoryReverseTransfer;
  return {
    serviceProviderId,
    serviceProviderLocationId,
    partDetails,
    isComingIn
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPartSerialNumberDetails
    },
    dispatch
  );

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(memo(DashSearch));

