import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DashInput from "./DashInput/DashInput";
import TransferDropDown from "../TransferDropDown/TransferDropDown";
import RMADashMain from "./RMADashMain/RMADashMain";
import "../../../../styles/_reactTable-overrides.scss";
import {Modal,message,Button, Row, Col, Layout , Steps , Switch, Typography, Space, Divider, Alert} from 'antd'
import {
  startReturnMerchandiseAuthorization,
  toggleRmaValidation,
  saveSelectedFromAddressDetails,
  saveSelectedToAddress,
  navigateToPreviousExternalTransfersFromExternalTransfersDashboard,
  onModelCancel
} from "../../../../actions/ExternalTransferActions";
import {
  cleanExistingData
} from "../../../../actions/viewExternalTransferActions";
import { showErrorMessageET, showGroupErrorMessages } from "../../../../actions/errorActions";

const {Header, Content, Footer}=Layout;
const { Step } = Steps;
const { Text } = Typography;

class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      yes: false,
      no: true,
      toAddress: {},
      fromAddress: {},
      proceedWithExistingExternalReferenceNumber: null,
      externalReferenceNumber: null,
      modelVisible:false
    };
    this.handleChange = this.handleChange.bind(this);
    this.extReferenceNumberChanged = this.extReferenceNumberChanged.bind(this);
    this.startReturnMerchandiseAuthorizationProcess = this.startReturnMerchandiseAuthorizationProcess.bind(
      this
    );
    this.closeRmaModal = this.closeRmaModal.bind(this);
    this.ExtTransferGoingToChange = this.ExtTransferGoingToChange.bind(this);
    this.ExtTransfercomingFromChange = this.ExtTransfercomingFromChange.bind(
      this
    );
    this.proceedWithExistingReferenceNumExt = this.proceedWithExistingReferenceNumExt.bind(
      this
    );
    this.buttonOnClick = this.buttonOnClick.bind(this);
  }

  buttonOnClick() {
    this.props.navigateToPreviousExternalTransfersFromExternalTransfersDashboard();
    this.props.cleanExistingData();
  }

  handleChange(checked,event) {
    console.log(`checked is ${checked}`)
    if (checked === true) {
      this.setState({
        yes: true,
        no: false
      });
    }
    if (checked === false) {
      this.setState({
        yes: false,
        no: true,
        extReferenceNumber: ""
      });
    }
  }

  extReferenceNumberChanged(extReferenceNumber) {
    console.log(extReferenceNumber)
    if (extReferenceNumber !== undefined && extReferenceNumber !== null) {
      this.setState({
        externalReferenceNumber: extReferenceNumber
      })
    }
  }

  startReturnMerchandiseAuthorizationProcess() {
    let messages = [];
    if (Object.keys(this.state.fromAddress).length === 0) {
      messages.push('Source must be selected!!');
    } if (Object.keys(this.state.toAddress).length === 0) {
      messages.push('Destination must be selected!!');
    } if ((this.state.yes === null) || (this.state.no === null)) {
      messages.push('Please select Yes if you have reference number or No if you do not have reference number');
    } if (this.state.yes === true && this.state.no === false) {
      if (this.state.externalReferenceNumber === null) {
        messages.push('Reference Number cannot be blank!');
      }
    }
    if (messages.length > 0) {
      this.props.showGroupErrorMessages(messages);
      return messages;
    } else {
      this.setState(
        {
          proceedWithExistingExternalReferenceNumber: false
        },
           () => {this.props.startReturnMerchandiseAuthorization(
            this.state.externalReferenceNumber,
            this.state.fromAddress,
            this.state.toAddress,
            this.props.userId,
            this.state.proceedWithExistingExternalReferenceNumber,
            this.state.yes,
            this.state.no
          )
        }
      );
    }
  }

  ExtTransferGoingToChange(value) {
    if (value !== "select") {
      var toAddress = this.props.DestinationAddressDetails.filter(address => {
        return address.name === value;
      });
      this.setState(
        {
          toAddress: toAddress[0]
        },
        () => {
          this.props.saveSelectedToAddress(this.state.toAddress);
        }
      );
    } else {
      this.setState({
        toAddress: {}
      })
    }
  }

  closeRmaModal() {
    this.props.toggleRmaValidation();
  }

  proceedWithExistingReferenceNumExt() {
    this.setState(
      {
        proceedWithExistingExternalReferenceNumber: true
      },
      () => {
        this.props.startReturnMerchandiseAuthorization(
          this.state.extReferenceNumber,
          this.state.fromAddress,
          this.state.toAddress,
          this.props.userId,
          this.state.proceedWithExistingExternalReferenceNumber,
          this.state.yes,
          this.state.no
        );
      }
    );
  }

  ExtTransfercomingFromChange(value) {
    console.log(value)
    if (value !== "select") {
      var fromAddress = this.props.userDefaultLocationAddressDetails.userLocationAddressDetails.filter(
        location => {
          return (location.serviceProviderLocationId = value);
        }
      );
      this.setState(
        {
          fromAddress: fromAddress[0]
        },
        () => {
          this.props.saveSelectedFromAddressDetails(this.state.fromAddress);
        }
      );
    } else {
      this.setState({
        fromAddress: {}
      })
    }
  }

  render() {
    return (
      <>
        <Row >
          <Modal  visible={this.props.rmaAlreadyExists}  closable={false}
          onOk={()=>this.props.startReturnMerchandiseAuthorization(this.state.externalReferenceNumber,this.state.fromAddress,this.state.toAddress, this.props.userId, 
            true,this.state.yes,this.state.no)}
            onCancel={()=>this.props.onModelCancel()}>         
          <p>RMA with provided external reference number already exists</p>
          <p>Do you wish to create with same external reference number ?</p>
        </Modal>
          <Col span={7} style={{paddingLeft:'1rem',paddingTop:'1rem',paddingBottom:'1rem',paddingRight:'0.5rem'}}>
            <Layout style={{height:'100%',backgroundColor:'white'}}>
              <Content style={{padding:'24px 35px'}}>
            <Row><TransferDropDown externalTransferComingFromChange={this.ExtTransfercomingFromChange} externalTransferGoingToChange={this.ExtTransferGoingToChange} /></Row>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Row>
                  {!this.props.hideRMASection ?
                    (<>
                      <Row style={{ width: '100%' }}>
                        <Col span={20}><Text strong>Do you have a Reference Number ?</Text></Col>
                        <Col span={4}><Switch key="refSwitch" defaultChecked={false} onChange={(checked) => this.handleChange(checked)}></Switch></Col>
                      </Row>
                      {this.state.yes ?
                        (<Row><DashInput
                          nextStep={() => console.info("Scanning")}
                          placeholder="Enter Reference Number #"
                          rules='required|ReferenceNumber'
                          title="Ref Number"
                          onSubmit={value => this.extReferenceNumberChanged(value)}
                          type="Ref number" /></Row>) : ("")}
                    </>)
                    : 
                    <Row style={{ width: '100%' }}>
                    <Col span={7}><Text strong>Return ID </Text></Col>
                    <Col span={2}><Text strong>:</Text></Col>
                    <Col span={15}><Text >{this.props.rmaNumber}</Text></Col>
                    </Row>}
                  <br />
                  <Divider />
                  <br />
                </Row>
                <Row style={{ float: "right" }}>
                  <br />
                  <Button type="primary" disabled={this.props.hideRMASection} onClick={()=>{let messages=this.startReturnMerchandiseAuthorizationProcess();
                  if(messages){for(let m of messages){message.error(m,8)}}}}>START RMA</Button>
                </Row>
              </Space>
                    </Content>
                    <Footer style={{backgroundColor:'white'}}> 

                    <Button style={{float: "right"}} type="primary" size="large" onClick={this.buttonOnClick} >VIEW PREVIOUS TRANSFERS</Button>
                    </Footer>
            </Layout>
            </Col>
          <Col span={17} style={{paddingLeft:'0.5rem',paddingTop:'1rem',paddingBottom:'1rem',paddingRight:'1rem'}}>
            <RMADashMain />
          </Col>
        </Row>
        </>
    );
  }
}

const mapStateToProps = ({ error, externalTransfer }) => {
  const {
    userDefaultLocationAddressDetails,
    DestinationAddressDetails,
    rmaAlreadyExists,
    userId,
    hideRMASection,
    selectedFromAddress,
    selectedToAddress,
    rmaNumber
  } = externalTransfer;
  const { showErrorET, errorMessageET, showErrorMessages } = error;
  return {
    userDefaultLocationAddressDetails,
    DestinationAddressDetails,
    rmaAlreadyExists,
    userId,
    hideRMASection,
    showErrorET,
    errorMessageET,
    selectedFromAddress,
    selectedToAddress,
    showErrorMessages,
    rmaNumber
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      startReturnMerchandiseAuthorization,
      toggleRmaValidation,
      saveSelectedFromAddressDetails,
      saveSelectedToAddress,
      showErrorMessageET,
      showGroupErrorMessages,
      navigateToPreviousExternalTransfersFromExternalTransfersDashboard,
      cleanExistingData,
      onModelCancel
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
