import { Get, Post, uriName, eventsUriName } from "../utilities/apiUtility";
import config from "../config";
import { hasError, showErrorMessage } from './errorActions';
import { isEmpty } from '../utilities/genericUtility';
export const SAVE_PART_DETAILS_FOR_311_GENERATION = "SAVE_PART_DETAILS_FOR_311_GENERATION";
export const SAVE_DESTINATION_SP_PART_LOCATION_ID =
  "SAVE_DESTINATION_SP_PART_LOCATION_ID";
export const SAVE_MOVE_DETAILS = "SAVE_MOVE_DETAILS";
export const SAVE_PART_DETAILS = "SAVE_PART_DETAILS";
export const SAVE_PART_DETAILS_ON_CONSOLE = "SAVE_PART_DETAILS_ON_CONSOLE";
export const SAVE_PART_DETAILS_IN_ARRAY = "SAVE_PART_DETAILS_IN_ARRAY";
export const SAVE_SERVICE_PROVIDER_ID = "SAVE_SERVICE_PROVIDER_ID";
export const SAVE_SERVICE_PROVIDER_LOCATION_ID =
  "SAVE_SERVICE_PROVIDER_LOCATION_ID";
export const SAVE_SERVICE_PROVIDER_LOCATION_INFO =
  "SAVE_SERVICE_PROVIDER_LOCATION_INFO";
export const SAVE_SERVICE_PROVIDER_SUB_LOCATION_INFO =
  "SAVE_SERVICE_PROVIDER_SUB_LOCATION_INFO";
export const SAVE_SOURCE_SP_PART_LOCATION_ID =
  "SAVE_SOURCE_SP_PART_LOCATION_ID";
export const TRANSFER_COMPLETE = "TRANSFER_COMPLETE";
export const START_NEW_TRANSFER = "START_NEW_TRANSFER";
export const SAVE_ERRORED_PART_SERIAL_NUMBER_DETAILS = "SAVE_ERRORED_PART_SERIAL_NUMBER_DETAILS";
export const SAVE_SUCCESS_RESPONSE = "SAVE_SUCCESS_RESPONSE";
export const HIDE_VALID = "HIDE_VALID";
export const SAVE_ERRORED_ARRAY = "SAVE_ERRORED_ARRAY";
export const UPDATE_PART_DETAILS = "UPDATE_PART_DETAILS";
export const SET_TRANSFER_COMPLETE_TO_FALSE = "SET_TRANSFER_COMPLETE_TO_FALSE";

export const setTransferComplete = () => dispatch => {
  dispatch({ type: TRANSFER_COMPLETE });
};

export const setTransferCompleteToFalse = () => dispatch => {
  dispatch({ type: SET_TRANSFER_COMPLETE_TO_FALSE });
};

export const getServiceProviderLocations = serviceProviderId => async dispatch => {
  try {
    const response = await Get(
      uriName,
      `/v1/gateway/get/${serviceProviderId}?serviceProviderId=${serviceProviderId}`
    );
    dispatch(saveServiceProviderLocationInfo(response));
    dispatch(saveServiceProviderId(serviceProviderId));
  } catch (error) {
    console.log(error);
  }
};

export const getServiceProviderSubLocations = (serviceProviderId, serviceProviderLocationId, spSourcePartLocationId) => async dispatch => {
  try {
    const response = await Get(
      uriName,
      `/v1/gateway/get/${serviceProviderId}?serviceProviderLocationId=${serviceProviderLocationId}`
    );
    dispatch(saveServiceProviderSubLocationInfo(response));
    dispatch(saveServiceProviderLocationId(serviceProviderLocationId));
    dispatch(
      saveSpSourcePartLocationId(spSourcePartLocationId[0].SP_PART_LOCATION_ID)
    );
    console.log(spSourcePartLocationId[0].SP_PART_LOCATION_ID);
    console.log(response);
  } catch (error) {
    console.log(error);
  }
};

export const getServiceProviderSubLocationsForReverseTransfer = (serviceProviderId, serviceProviderLocationId) => async dispatch => {
  try {
    const response = await Get(
      uriName,
      `/v1/gateway/get/${serviceProviderId}?serviceProviderLocationId=${serviceProviderLocationId}`
    );
    dispatch(saveServiceProviderSubLocationInfo(response));
    dispatch(saveServiceProviderLocationId(serviceProviderLocationId));
    console.log(serviceProviderLocationId);
  } catch (error) {
    console.log(error);
  }
};


export const getPartSerialNumberDetails = (partDetails,serviceProviderId, serviceProviderLocationId, serialNumber, isComingIn) => async dispatch => {
  try {
    let exists = await partExists(partDetails,serialNumber);
    if(!exists){
    const postData = {
      companyId: serviceProviderId,
      companyPhysicalLocationId: serviceProviderLocationId,
      serialNumber: serialNumber
    };
    const res = await Post(uriName, `/v1/gateway`, postData);
    if (hasError(res)) {
      dispatch(showErrorMessage('Unable to get the data from the server.'));
    } else if (res && !isEmpty(res)) {
      if (isComingIn) {
        dispatch(savePartDetails(res));
        dispatch(savePartDetailsFor311Generation(res));
        dispatch(saveSuccessResponse());
        setTimeout(() => dispatch({ type: HIDE_VALID }), 1000);
      } else {
        if (res[0].statusCode !== "DEAD_ON_ARRIVAL" && res[0].statusCode !== "PENDING_RECEIVING" && res[0].statusCode !== "DEFECTIVE" && res[0].statusCode !== "UNINSTALLED" && res[0].statusCode !== "NOT_REQUIRED") {
          dispatch(savePartDetails(res));
          dispatch(savePartDetailsFor311Generation(res));
          dispatch(saveSuccessResponse());
          setTimeout(() => dispatch({ type: HIDE_VALID }), 1000);
        }
      }
    } else if (res && isEmpty(res)) {
      dispatch(showErrorMessage('Serial is not present at the Location'));
    } if (res && !isEmpty(res) && res[0].statusCode === "PENDING_RECEIVING") {
      dispatch(showErrorMessage('Serial is in "PENDING_RECEIVING" status. Receive it first'));
    } if (res && !isEmpty(res) && !isComingIn && (res[0].statusCode === "DEAD_ON_ARRIVAL" || res[0].statusCode === "DEFECTIVE" || res[0].statusCode === "UNINSTALLED" || res[0].statusCode === "NOT_REQUIRED")) {
      dispatch(showErrorMessage(' Serial is in ' + res[0].statusCode + ' status. Cannot transfer! '))
    }
  }else{
    dispatch(showErrorMessage('Duplicate serial'));
  }
}
  catch (error) {
    if (hasError(error)) {
      dispatch(showErrorMessage('Invalid Serial Number. Please try again!'));
    }
  }
};

export const movePartLocation = (serviceProviderId, spSourcePartLocationId, spDestinationPartLocationId, partDetails, partDetails311, isComingIn) => async dispatch => {
  try {
    const erroredSerialNumbers = {
      serializedPartList: []
    };

    let erroredArray = [];

    const nonErroredSerialNumbers = {
      serializedPartList: []
    };
    let postData = {
      username: "user",
      companyId: serviceProviderId,
      locationIdOrNumber: spDestinationPartLocationId,
      sourceLocationIdOrNumber: spSourcePartLocationId,
      sourceCompanyId: serviceProviderId,
      serviceAdministratorId: "43",
      serializedPartList: partDetails
    };
    const res = await Post(uriName, `/v1/gateway/transfer`, postData);
    console.log(res);
    if (hasError(res)) {
      console.log('Error occured during transfer')
      dispatch(showErrorMessage('Unable to get the data from the server.'));
    } else if (res && res.serializedPartList) {
      res.serializedPartList.forEach(element => {
        if (element.errors) {
          erroredSerialNumbers.serializedPartList.push(element);
        } else {
          nonErroredSerialNumbers.serializedPartList.push(element);
        }
      });
      console.log(erroredSerialNumbers);
      console.log(nonErroredSerialNumbers);
      console.log(partDetails311);
      if (nonErroredSerialNumbers && !isEmpty(nonErroredSerialNumbers) && !isEmpty(nonErroredSerialNumbers.serializedPartList)) {
        let partDetailsFor311Generation = [];
        dispatch(saveMoveDetails(nonErroredSerialNumbers));
        nonErroredSerialNumbers.serializedPartList.forEach(element => {
          partDetails311.forEach(item => {
            if (element.serialNumber === item.serialNumber) {
              let partDetailFor311Generation = {};
              partDetailFor311Generation.serialNumber = item.serialNumber;
              partDetailFor311Generation.statusCode = item.statusCode;
              partDetailFor311Generation.partNumber = item.partNumber;
              partDetailFor311Generation.spPartInventoryId = item.spPartInventoryId;
              partDetailFor311Generation.valuationType = item.valuationType;
              partDetailsFor311Generation.push(partDetailFor311Generation);
            }
          })
        })
        if (config.AllowedClient.storeNumber && config.AllowedClient.storeNumber === 500) {
          dispatch(generate311XMLRequest(partDetailsFor311Generation, isComingIn));
        }
      }
      if (erroredSerialNumbers && !isEmpty(erroredSerialNumbers)) {
        if (erroredSerialNumbers.serializedPartList) {
          erroredSerialNumbers.serializedPartList.forEach(element => {
            let erroredObject = {};
            if (element.errors[0]) {
              erroredObject.serialNumber = element.serialNumber;
              if (element.errors[0].message) {
                erroredObject.error = element.errors[0].message;
              } else if (element.errors[0].description) {
                erroredObject.error = element.errors[0].description;
              }
            } else if (element.errors) {
              erroredObject.serialNumber = element.serialNumber;
              if (element.errors.message) {
                erroredObject.error = element.errors.message;
              } else if (element.errors.description) {
                erroredObject.error = element.errors.description;
              }
            }
            erroredArray.push(erroredObject);
          })
        }
        console.log(erroredArray);
        if (erroredArray && !isEmpty(erroredArray)) {
          dispatch(saveErroredArray(erroredArray));
        }
        //dispatch(saveErroredPartSerialNumberDetails(erroredSerialNumbers));
      }
    }
    dispatch(setTransferComplete());
  }
  catch (error) {
    console.log(error);
  }
};

export const generate311XMLRequest = (partDetails311, isComingIn) => async dispatch => {
  try {
    let postData = {
      isComingIn: isComingIn,
      serializedPartList: partDetails311
    }
    console.log(postData)
    const res = await Post(uriName, `/v1/gateway/create`, postData);
    console.log(res);
  }
  catch (error) {
    console.log(error);
  }
}

const saveServiceProviderSubLocationInfo = ({
  serviceProviderSubLocationName
}) => ({
  type: SAVE_SERVICE_PROVIDER_SUB_LOCATION_INFO,
  serviceProviderSubLocationName
});

const saveServiceProviderLocationInfo = ({ serviceProviderLocationName }) => ({
  type: SAVE_SERVICE_PROVIDER_LOCATION_INFO,
  serviceProviderLocationName
});

const saveServiceProviderId = serviceProviderId => ({
  type: SAVE_SERVICE_PROVIDER_ID,
  serviceProviderId
});

const saveServiceProviderLocationId = serviceProviderLocationId => ({
  type: SAVE_SERVICE_PROVIDER_LOCATION_ID,
  serviceProviderLocationId
});

const savePartDetails = partDetail => ({ type: SAVE_PART_DETAILS, partDetail });

const savePartDetailsFor311Generation = partDetail311 =>
  ({
    type: SAVE_PART_DETAILS_FOR_311_GENERATION, partDetail311
  })

const saveMoveDetails = nonErroredSerialNumbers => ({ type: SAVE_MOVE_DETAILS, nonErroredSerialNumbers });

const saveErroredPartSerialNumberDetails = erroredSerialNumbers => ({ type: SAVE_ERRORED_PART_SERIAL_NUMBER_DETAILS, erroredSerialNumbers });

const saveErroredArray = erroredArray => ({ type: SAVE_ERRORED_ARRAY, erroredArray });

export const saveSpSourcePartLocationId = spSourcePartLocationId => ({
  type: SAVE_SOURCE_SP_PART_LOCATION_ID,
  spSourcePartLocationId
});

export const saveSpDestinationPartLocationId = spDestinationPartLocationId => ({
  type: SAVE_DESTINATION_SP_PART_LOCATION_ID,
  spDestinationPartLocationId
});

export const updatedPartDetails = (index) => ({
  type: UPDATE_PART_DETAILS,
  index
})

export const startNewTransfer = () => ({ type: START_NEW_TRANSFER });

export const saveSuccessResponse = () => ({ type: SAVE_SUCCESS_RESPONSE });

const partExists = async (partDetails,serialNumber)=>{
  for(let acceptedPart of partDetails){
    if(acceptedPart.serialNumber===serialNumber){
      return true;
    }
  }
return false;
}