import React, { memo } from "react";
import { connect } from "react-redux";
import DropDown from "../../../Commons/DropDown/DropDown";
import { bindActionCreators } from "redux";
import { Input,  Select, Row, Col, Typography, Divider } from 'antd';
const { Option } = Select;
const { Text } = Typography;

const TransferDropDown = ({ 
    externalTransferComingFromChange, 
    userDefaultLocationAddressDetails, 
    DestinationAddressDetails,
    externalTransferGoingToChange,
    populateDropDownOnLoad
 }) => {
    let userDefaultLocationAddressDetailsFiltered = null;
    let destinationAddressesFiltered = null;
    let decidedSource= userDefaultLocationAddressDetails.userLocationAddressDetails[0].name;
    let decidedDestination=DestinationAddressDetails[0].name;
    userDefaultLocationAddressDetailsFiltered = userDefaultLocationAddressDetails.userLocationAddressDetails.map((location,key) => {
        return (
            <Option key={key} value={location.serviceProviderLocationId}>
              {location.name}
            </Option>
          );
    });
    destinationAddressesFiltered = DestinationAddressDetails.map((location,key) => {
        return (
            <Option key={key} value={location.name}>
                {location.name}
            </Option>
        );
    });

    return (
        <div style={{width:'100%'}}>
                <Row>
                <Col span={7}><Text strong>Source</Text></Col>
                <Col span={2}><Text strong>:</Text></Col>
                <Col span={14}> {populateDropDownOnLoad ?
                    <Text strong>{decidedSource}</Text> :
                    <Select defaultValue="select" style={{ width: 175 }} onChange={(value) => externalTransferComingFromChange(value)}>
                        <Option value="select">Select Source</Option>
                        {userDefaultLocationAddressDetailsFiltered}
                    </Select>
                }</Col>
            </Row>
            <br/>
            <Row>
                <Col span={7}><Text strong>Destination</Text></Col>
                <Col span={2}><Text strong>:</Text></Col>
                <Col span={15}> {populateDropDownOnLoad ?
                    <Text strong>{decidedDestination}</Text> :
                    <Select  defaultValue="select" style={{ width: 175 }} onChange={(value)=>externalTransferGoingToChange(value)}>
                        <Option value="select">Select Destination</Option>
                        {destinationAddressesFiltered}
                    </Select>
                }</Col>
            </Row>
            <Divider />
        </div>
    );
};

const mapStateToProps = ({ externalTransfer }) => {
    const { userDefaultLocationAddressDetails, DestinationAddressDetails, populateDropDownOnLoad } = externalTransfer;
    return {
        userDefaultLocationAddressDetails,
        DestinationAddressDetails,
        populateDropDownOnLoad
    };
};

const mapDispatchToProps = dispatch => {
    bindActionCreators(
        {

        }, dispatch
    )
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransferDropDown);