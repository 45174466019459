export const SHOW_ERROR = 'SHOW_ERROR';
export const HIDE_ERROR = 'HIDE_ERROR';
export const SHOW_ERROR_ET = 'SHOW_ERROR_ET';
export const HIDE_ERROR_ET = 'HIDE_ERROR_ET';
export const SHOW_GROUP_ERRORS = 'SHOW_GROUP_ERRORS';

const error = {
  code: '',
  message: '',
  messages: []
};

const errorMessages = {
    SERVER_ISSUE: 'Please contact system Administrator.'
  };  

export const showErrorMessage = message => dispatch => {
    error.message = message;
    dispatch({ type: SHOW_ERROR, payload: error.message === '' ? message : error.message });
    setTimeout(() => dispatch({ type: HIDE_ERROR }), 1000);
  };

  export const showErrorMessageET = message => dispatch => {
    error.message = message;
    dispatch({ type: SHOW_ERROR_ET, payload: error.message === '' ? message : error.message });
    setTimeout(() => dispatch({ type: HIDE_ERROR_ET }), 1000);
  };

  export const showGroupErrorMessages = messages => dispatch => {
    dispatch ({ type: SHOW_GROUP_ERRORS, payload: messages });
    setTimeout(() => dispatch({ type: HIDE_ERROR_ET }), 3000);
  }
export const hasError = res => {
    error.code = '';
    error.message = '';
    if (res && res.Error) {
      error.code = res.Error.Code;
      error.message = errorMessages[error.code] ? errorMessages[error.code] : res.Error.Message;
      return true;
    } else if (res && res.error) {
      const err = res.error;
      error.code = err.code;
      error.message = errorMessages[error.code] ? errorMessages[error.code] : err.description;
      return true;
    } else if (res instanceof Error) return true;
    else return false;
  };