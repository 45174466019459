import React, { memo } from "react";
import Button from "../Commons/Button/Button";
import Modal from "../Commons/Modal/Modal";

const SessionOut = ({ closeWindow }) => (
  <Modal
    title="Your session has expired."
    message={
      <>
        <p>
          Please close this window and click the “Inventory Facilities Transfer”
          link
        </p>
        <p>in ServiceBench to start a new session.</p>
        <p>Sessions are valid for 8 hours.</p>
      </>
    }
    buttons={<Button text="Close Window" onClick={closeWindow} />}
  />
);

export default memo(SessionOut);
