import { userInfo } from "../utilities/apiUtility";
import { Get, Post, uriName } from "../utilities/apiUtility";
import { parseJwt } from "../utilities/genericUtility";
import { hasError, showErrorMessage, showErrorMessageET } from "./errorActions";
import { isEmpty } from "../utilities/genericUtility";
export const SAVE_RMA_ACTION = "SAVE_RMA_ACTION";
export const HIDE_VALID_VT = "HIDE_VALID_VT";
export const SAVE_SEARCH_SUCCESS_RESPONSE = "SAVE_SEARCH_SUCCESS_RESPONSE";
export const SAVE_SEARCHED_RMA = "SAVE_SEARCHED_RMA";
export const PACKING_LIST_CREATED = "PACKING_LIST_CREATED";
export const GET_INITIAL_STATE = "GET_INITIAL_STATE";
export const PRINT_PACKING_LIST = "PRINT_PACKING_LIST";
export const SAVE_RMA_RELATED_DETAILS = "SAVE_RMA_RELATED_DETAILS";
export const SHIPPING_LABEL_ON_CLICK = "SHIPPING_LABEL_ON_CLICK";
export const ENABLE_SHIPPING_LABEL_ON_CLICK ="ENABLE_SHIPPING_LABEL_ON_CLICK";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CLOSE_PACKING_LIST_MODAL = "CLOSE_PACKING_LIST_MODAL";
export const CLOSE_SHIPPING_LABEL_MODEL = "CLOSE_SHIPPING_LABEL_MODEL";
export const SAVE_SELECTED_ACTION = "SAVE_SELECTED_ACTION";
export const SAVE_APPLIED_DATES_EXT_TRANSFERS ="SAVE_APPLIED_DATES_EXT_TRANSFERS";
export const ENABLE_COMPLETE_TRANSFER_SECTION = "ENABLE_COMPLETE_TRANSFER_SECTION";
export const CLEAN_DATE_RANGE_DATA = "CLEAN_DATE_RANGE_DATA";
export const SAVE_LAST_SEVEN_DAYS_EXTERNAL_TRANSFERS_DATA ="SAVE_LAST_SEVEN_DAYS_EXTERNAL_TRANSFERS_DATA";
export const CLEAN_SEVEN_DAYS_DATA = "CLEAN_SEVEN_DAYS_DATA";
export const SAVE_DATE_RANGE_DATA = "SAVE_DATE_RANGE_DATA";
export const CLEAN_FROM_AND_TO_DATES = "CLEAN_FROM_AND_TO_DATES";
export const INITIATED_RMA = "INITIATED_RMA";
export const CLEAN_EXISTING_DATA = "CLEAN_EXISTING_DATA";
export const SET_TABLE_DATA="SET_TABLE_DATA";
export const SET_TABLE_LOADING="SET_TABLE_LOADING";
export const SET_TABLE_DATA_AFTER_ACTION_SELECTION="SET_TABLE_DATA_AFTER_ACTION_SELECTION";
export const GOTO_ACTION_FROM_VIEW_EXTERNAL_TRANSFERS="GOTO_ACTION_FROM_VIEW_EXTERNAL_TRANSFERS";
export const NAVIGATE_TO_EXTERNAL_TRANSFERS="NAVIGATE_TO_EXTERNAL_TRANSFERS";
export const POPULATE_RMA_RELATED_ADDRESS_DETAILS="POPULATE_RMA_RELATED_ADDRESS_DETAILS"

export const setTableData = (data) =>async dispatch =>{
  console.log(data);
  dispatch({ type: SET_TABLE_DATA_AFTER_ACTION_SELECTION, data});
}

export const gotoETnPerform = (invid,rmaNumber,perform,status) => async dispatch =>{
  dispatch({type:GOTO_ACTION_FROM_VIEW_EXTERNAL_TRANSFERS, returnId:invid, perform:perform, rmaNumber:rmaNumber, status:status});
  try {
    const postData = {
      companyId: userInfo.serviceProviderId,
      serviceProviderLocationId: userInfo.serviceProviderLocationId, 
      inventoryRtnId: invid

    };
    const response = await Post(uriName, `/v1/rmatransfers`, postData);
    console.log(response)
    await dispatch({ type: SAVE_RMA_RELATED_DETAILS, response});
    await dispatch({ type: POPULATE_RMA_RELATED_ADDRESS_DETAILS,response });
    await dispatch({type: NAVIGATE_TO_EXTERNAL_TRANSFERS});
  } catch (err) {
    console.log(err);
  }
  
}

export const getExternalTransfersForLastSevenDays = serviceProviderLocationIdExternalTransfers => async dispatch => {
  try {
    dispatch({ type: SET_TABLE_LOADING});
    const postData = {
      fromDate: "",
      toDate: "",
      companyId: userInfo.serviceProviderId,
      serviceProviderLocationId: serviceProviderLocationIdExternalTransfers
        ? serviceProviderLocationIdExternalTransfers
        : userInfo.serviceProviderLocationId,
      sevenDays: true
    };
    const response = await Post(uriName, `/v1/rmatransfers`, postData);
    console.log(response);
    dispatch({ type: SET_TABLE_DATA, response});
  } catch (err) {
    console.log(err);
  }
};

export const getExternalTransfersForSelectedRange = (
  fromDate,
  toDate,
  serviceProviderLocationIdExternalTransfers
) => async dispatch => {
  try {
    dispatch({ type: SET_TABLE_LOADING});
    const fromTime = "00:00:00";
    const toTime = "00:00:00";
    let dateFrom = new Date(fromDate);
    const formattedFromDate =
      dateFrom.getFullYear() +
      "-" +
      (dateFrom.getMonth() + 1) +
      "-" +
      (dateFrom.getDate()+1) +
      " " +
      fromTime;
    let dateTo = new Date(toDate);
    const formattedToDate =
      dateTo.getFullYear() +
      "-" +
      (dateTo.getMonth() + 1) +
      "-" +
      (dateTo.getDate()+1) +
      " " +
      toTime;
      console.log(formattedFromDate);
    const postData = {
      rmaSearch: false,
      fromDate: formattedFromDate,
      toDate: formattedToDate,
      companyId: userInfo.serviceProviderId,
      serviceProviderLocationId: serviceProviderLocationIdExternalTransfers
        ? serviceProviderLocationIdExternalTransfers
        : userInfo.serviceProviderLocationId,
      sevenDays: false
    };
    const response = await Post(uriName, `/v1/rmatransfers`, postData);
    console.log(response);
    dispatch({ type: SET_TABLE_DATA, response});
  } catch (err) {
    console.log(err);
  }
};

export const getInitialState = () => ({type: GET_INITIAL_STATE});
export const cleanExistingData = () => ({type: CLEAN_EXISTING_DATA});

export const getRMANumberDetails = rmaNumber => async dispatch => {
  try {
    dispatch({ type: SET_TABLE_LOADING});
    const postData = {
      companyId: userInfo.serviceProviderId,
      serviceProviderLocationId: userInfo.serviceProviderLocationId, 
      rmaSearch: true,
      rmaNumber: rmaNumber };
    const response = await Post(uriName, `/v1/rmatransfers`, postData);
    dispatch({ type: SET_TABLE_DATA, response});
  } catch (err) {
    console.log(err);
  }
};

export const saveSearchSuccessResponse = () => ({
  type: SAVE_SEARCH_SUCCESS_RESPONSE
});

export const saveSearchedRMADetails = response => ({
  type: SAVE_SEARCHED_RMA,
  response
});
