import uuid from "uuid";
import { uriName, Get, userInfo } from "./apiUtility";
import { isEmpty, parseJwt } from "./genericUtility";

export const updateSessionId = () => (userInfo.sessionId = uuid.v4());

let waitTime = 5 * 30 * 1000;

const hasError = res => {
  if ((res && res.Error) || (res && res.error)) return true;
  else if (res instanceof Error) return true;
  else return false;
};

export const updateCurrentSession = callback => {
  if (!isEmpty(userInfo.token)) {
    const jwt = parseJwt(userInfo.token);
    setSessionLogout(jwt.exp, callback);
    const userDetails = jwt.username.split("|");
    userInfo.companyId = userDetails[0];
    userInfo.userName = userDetails[1];
  }
};

export const setSessionLogout = (ts, callback) => {
  const sessionExpiry = new Date(ts * 1000);
  const difference = sessionExpiry - Date.now();
  if (difference < 0) waitTime = 0;
  const refreshToDoAt = difference - waitTime;
  setTimeout(function() {
    refreshCurrentSession(callback);
  }, refreshToDoAt);
};

const refreshCurrentSession = async callback => {
  try {
    const res = await Get(uriName, `/v1/gateway/refresh?refreshToken=${userInfo.refreshToken}&token=${userInfo.token}`);
    console.log(res);
    if (hasError(res)) {
      setTimeout(() => {
        callback();
      }, waitTime - 1000);
    } else {
      userInfo.token = res.access_token;
      userInfo.refreshToken = res.refresh_token;
      updateCurrentSession(callback);
    }
  } catch (error) {
    setTimeout(() => {
      callback();
    }, waitTime);
  }
};
