import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setTransferComplete, movePartLocation } from "../../../actions/inventoryTransferActions";
import DashSearch from "../DashSearch/DashSearch";
import DashSidebarButton from "../DashSidebarButton/DashSidebarButton";
import DashTable from "../DashTable/DashTable";
import "../../../styles/_reactTable-overrides.scss";
import "./DashMain.scss";

class DashMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { resumeable, rowSelected, tableContent } = this.props;
    const { serviceProviderId, spSourcePartLocationId, spDestinationPartLocationId, spSourcePartLocationIdReverse, spDestinationPartLocationIdReverse, partDetails, partDetails311, isComingIn } = this.props;
    return (
      <main className="dash-main" role="main" id="main-content">
        <DashSearch />
        <DashTable
          orders={tableContent}
          rowSelected={rowSelected}
          resumeable={resumeable}
        />
        <div className="dash-main-bttn-cntnr">
          <DashSidebarButton
            className="width-50"
            disable={false}
            title="Complete Transfer"
            onClick={ () => isComingIn ? this.props.movePartLocation( serviceProviderId, spSourcePartLocationIdReverse, spDestinationPartLocationIdReverse, partDetails, partDetails311, isComingIn) : this.props.movePartLocation( serviceProviderId, spSourcePartLocationId, spDestinationPartLocationId, partDetails, partDetails311, isComingIn)}
          />
        </div>
      </main>
    );
  }
}

DashMain.defaultProps = {};

const mapStateToProps = ({ inventoryTransfer, inventoryReverseTransfer }) => {
  const { serviceProviderId, spSourcePartLocationId, spDestinationPartLocationId, partDetails, partDetails311 } = inventoryTransfer;
  const { spSourcePartLocationIdReverse, spDestinationPartLocationIdReverse, isComingIn } = inventoryReverseTransfer
  return {
    serviceProviderId,
    spSourcePartLocationId,
    spDestinationPartLocationId,
    spSourcePartLocationIdReverse,
    spDestinationPartLocationIdReverse,
    partDetails,
    partDetails311,
    isComingIn
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      movePartLocation
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashMain);
