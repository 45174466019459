import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Dashboard from './Dashboard/Dashboard';

class ExternalTransfers extends React.Component {
    constructor(){
        super();
    }

    render(){
        return (
            <Dashboard/>
        )
    }
}

export default ExternalTransfers;
