import React, { memo } from "react";
import "./DropDown.scss";

const DropDown = ({ children, id, label, name, onChange }) => (
  <div className="dropdown-cntnr">
    <label htmlFor={id}>{label}</label>
    <select id={id} name={name} onChange={onChange}>
      {children}
    </select>
  </div>
);

export default memo(DropDown);
