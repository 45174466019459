import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
//import "../../../styles/_reactTable-overrides.scss";
import { updatedPartDetails } from "../../../actions/inventoryTransferActions";
import { conditionalExpression } from "@babel/types";

const DashTable = ({ partDetails, resumeable, rowSelected, updatedPartDetails }) => {
  const columns = [
    {
      Header: "Part Number",
      accessor: 'partNumber'
    },
    {
      Header: "Serial Number",
      accessor: 'serialNumber'
    },
    {
      Header: "Delete",
      id:'delete',
      accessor: str => "delete",
      Cell: ({cell,row})=> (
      <span style={{cursor:'pointer',color:'blue',textDecoration:'underline'}}
            onClick={() => updatedPartDetails(row)}>
                Delete
              </span> 
      )}
  ];
  return (
    <ReactTable
      data={partDetails}
      columns={columns}
      getTrProps={(state, rowInfo, column) => {
        return {
          onClick: e => {
            if (resumeable) rowSelected(rowInfo.original);
          }
        }
      }}
      minRows={0}
      noDataText=""
      showPageSizeOptions={true}
      showPagination={false}
    />
  );
};

const mapStateToProps = ({ inventoryTransfer }) => {
  const { partDetails } = inventoryTransfer;
  return {
    partDetails
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updatedPartDetails
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashTable);
