import {
  ENABLE_EXTERNAL_TRANSFERS,
  NAVIGATE_TO_EXTERNAL_TRANSFERS,
  DISABLE_EXTERNAL_TRANSFERS,
  DISABLE_SCANNING_ABILITY,
  UPDATE_INVENTORY_RTN_ID,
  SET_EXTERNAL_TRANSFER_COMPLETE,
  SAVE_USER_DEFAULT_LOCATION_ADDRESS_DETAILS,
  SAVE_DUMMY_DESTINATION_DETAILS,
  NAVIGATE_TO_HISTORIC_EXTERNAL_TRANSFERS,
  POPULATE_RMA_RELATED_ADDRESS_DETAILS,
  RMA_ALREADY_EXISTS,
  SAVE_RESPONSE,
  SAVE_USER_DETAILS,
  TOGGLE_RMA_VALIDATION,
  SAVE_SERVICE_PROVIDER_ID_EXTERNAL_TRANSFERS,
  SAVE_SERVICE_PROVIDER_LOCATION_ID_EXTERNAL_TRANSFERS,
  SAVE_INVENTORY_RTN_ID,
  CLOSE_RMA_MODAL,
  SAVE_PART_DETAILS_EXTERNAL_TRANSFERS,
  SAVE_DUMMY_PART_DETAILS_EXTERNAL_TRANSFERS,
  SAVE_ET_SUCCESS_RESPONSE,
  HIDE_VALID_ET,
  SAVE_SELECTED_TO_ADDRESS,
  SAVE_SELECTED_FROM_ADDRESS,
  DELETE_SELECTED_PART,
  HIDE_RMA_SECTION,
  HIDE_DELETE_CAPABILITY,
  LABEL_GENERATION_RESPONSE,
  NAVIGATE_TO_VIEW_EXTERNAL_TRANSFERS,
  SET_SHOW_CALENDER_TO_TRUE,
  START_NEW_EXTERNAL_TRANSFER,
  SAVE_RMA_RELATED_DETAILS,
  SAVE_RMA_ACTION,
  SET_USER_INFO,
  UPDATE_PARTS_DETAILS,
  UPDATE_RMA_ACTION,
  CLEAR_PERFORM,
  UPDATE_ISLOADING
} from "../actions/ExternalTransferActions";
import {GOTO_ACTION_FROM_VIEW_EXTERNAL_TRANSFERS} from "../actions/viewExternalTransferActions";

const initialState = {
  enableExternalTransfers: null,
  partDetailsExternalTransfers: [],
  setExternalTransferComplete: null,
  userDefaultLocationAddressDetails: [],
  fromAddressDetails: [],
  dummyDestinationDetails: [],
  rmaAlreadyExists: null,
  userId: null,
  serviceProviderIdExternalTransfers: null,
  serviceProviderLocationIdExternalTransfers: null,
  inventoryRtnId: null,
  successMessageET: "",
  disableScan: true,
  showSuccessET: false,
  selectedToAddress: {},
  selectedFromAddress: {},
  dummyPartDetails: [],
  hideRMASection: false,
  showDeleteButton: true,
  labelGenerationResponse: null,
  viewExternalTransfers: false,
  rmaDetailsExternalTransfers: [],
  showCalendar: false,
  populateDropDownOnLoad: false,
  response: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ENABLE_EXTERNAL_TRANSFERS:
      return {
        ...state,
        enableExternalTransfers: true,
        setExternalTransferComplete: false,
        partDetailsExternalTransfers: [],
        rmaAlreadyExists: null,
        serviceProviderLocationIdExternalTransfers: null,
        inventoryRtnId: null,
        perform:null,
        rmaAction:null,
        rmaNumber:null,
        successMessageET: "",
        disableScan: true,
        showSuccessET: false,
        selectedToAddress: {},
        selectedFromAddress: {},
        dummyPartDetails: [],
        hideRMASection: false,
        labelGenerationResponse: null,
        viewExternalTransfers: false,
        populateDropDownOnLoad: false,
        rmaDetailsExternalTransfers: [],
        isLoading:false
      };
    case UPDATE_INVENTORY_RTN_ID:
      return {
        ...state,
        inventoryRtnId : action.inventoryRtnId
      }
    case SAVE_RMA_RELATED_DETAILS:
      return {
        ...state,
        partDetailsExternalTransfers: action.response.serialDetails,
        rmaAction: action.response.rmaDetails[0].rmaStatus,
        showDeleteButton: false
      };
    case SAVE_RESPONSE:
      console.log(action.response);
      return {
        ...state,
        response: action.response
      };
    case POPULATE_RMA_RELATED_ADDRESS_DETAILS:
      return {
        ...state,
        userDefaultLocationAddressDetails: action.response,
        populateDropDownOnLoad: true,
        dummyDestinationDetails: action.response.destinationAddressDetails,
        selectedFromAddress: action.response.userLocationAddressDetails[0],
        selectedToAddress: action.response.destinationAddressDetails[0]
      };
    case START_NEW_EXTERNAL_TRANSFER:
      return {
        ...state,
        viewExternalTransfers: false,
        setExternalTransferComplete: false,
        enableExternalTransfers: true,
        partDetailsExternalTransfers: [],
        inventoryRtnId: null,
        successMessageET: "",
        populateDropDownOnLoad: false,
        showSuccessET: false,
        hideRMASection: false,
        showDeleteButton: true,
        labelGenerationResponse: null,
        serviceProviderLocationIdExternalTransfers: null,
        rmaAlreadyExists: null,
        inventoryRtnId: null,
        perform:null,
        rmaAction:null,
        rmaNumber:null,
      };
    case NAVIGATE_TO_HISTORIC_EXTERNAL_TRANSFERS:
      return {
        ...state,
        setExternalTransferComplete: false,
        viewExternalTransfers: true
      };
    case NAVIGATE_TO_VIEW_EXTERNAL_TRANSFERS:
      return {
        ...state,
        viewExternalTransfers: true
      };
    case LABEL_GENERATION_RESPONSE:
      return {
        ...state,
        rmaAction:action.rmaAction
      };
    case NAVIGATE_TO_EXTERNAL_TRANSFERS:
      return {
        ...state,
        enableExternalTransfers: true,
        setExternalTransferComplete: false,
        viewExternalTransfers: false,
        rmaAlreadyExists: null,
        successMessageET: "",
        showSuccessET: false,
        hideRMASection: true
      };
    case SET_SHOW_CALENDER_TO_TRUE:
      return {
        ...state,
        showCalendar: true
      };
    case SAVE_PART_DETAILS_EXTERNAL_TRANSFERS:
      return {
        ...state,
        partDetailsExternalTransfers: [
          ...state.partDetailsExternalTransfers,
          {
            serialNumber: action.partDetailExternalTransfers.serialNumber,
            partNumber: action.partDetailExternalTransfers.partNumber,
            statusCode: action.partDetailExternalTransfers.partSerialStatusCode,
            spPartInventoryId:
              action.partDetailExternalTransfers.spPartInventoryId
          }
        ]
      };
    case HIDE_RMA_SECTION:
      return {
        ...state,
        hideRMASection: true,
        disableScan: false
      };
    case HIDE_DELETE_CAPABILITY:
      return {
        ...state,
        showDeleteButton: false
      };
    case SAVE_INVENTORY_RTN_ID:
      return {
        ...state,
        inventoryRtnId: action.response.inventoryRtnId,
        rmaNumber: action.response.rmaNumber
      };
    case SAVE_SERVICE_PROVIDER_ID_EXTERNAL_TRANSFERS:
      return {
        ...state,
        serviceProviderIdExternalTransfers: action.companyId
      };
    case SAVE_SERVICE_PROVIDER_LOCATION_ID_EXTERNAL_TRANSFERS:
      return {
        ...state,
        serviceProviderLocationIdExternalTransfers: action.spLocId
      };
    case DELETE_SELECTED_PART:
      let index = action.index._index;
      return {
        ...state,
        partDetailsExternalTransfers: state.partDetailsExternalTransfers.filter(
          (_, i) => i !== index
        )
      };
    case SAVE_USER_DETAILS:
      return {
        ...state,
        userId: action.userDetails.userId
      };
    case TOGGLE_RMA_VALIDATION:
      return {
        ...state,
        rmaAlreadyExists: false
      };
    case CLOSE_RMA_MODAL:
      return {
        ...state,
        rmaAlreadyExists: false
      };
    case RMA_ALREADY_EXISTS:
      return {
        ...state,
        rmaAlreadyExists: 'true'
      };
    case SAVE_DUMMY_DESTINATION_DETAILS:
      return {
        ...state,
        DestinationAddressDetails: action.response.DestinationAddressDetails
      };
    case SAVE_ET_SUCCESS_RESPONSE:
      return {
        ...state,
        showSuccessET: true,
        successMessageET: "Valid Serial Number. Success!"
      };
    case HIDE_VALID_ET:
      return {
        ...state,
        showSuccessET: false,
        successMessageET: ""
      };
    case SAVE_USER_DEFAULT_LOCATION_ADDRESS_DETAILS:
      return {
        ...state,
        userDefaultLocationAddressDetails: action.response
      };
    case DISABLE_EXTERNAL_TRANSFERS:
      return {
        ...state,
        enableExternalTransfers: false
      };
    case SET_EXTERNAL_TRANSFER_COMPLETE:
      return {
        ...state,
        setExternalTransferComplete: true
      };
    case SAVE_SELECTED_TO_ADDRESS:
      return {
        ...state,
        selectedToAddress: action.toAddress
      };
    case SAVE_SELECTED_FROM_ADDRESS:
      return {
        ...state,
        selectedFromAddress: action.fromAddress
      };
    case SAVE_DUMMY_PART_DETAILS_EXTERNAL_TRANSFERS:
      return {
        ...state,
        dummyPartDetails: action.partDummyDetails
      };
    case DISABLE_SCANNING_ABILITY:
      return {
        ...state,
        disableScan: true
      };
    case SAVE_RMA_ACTION:
      return {
        ...state,
        rmaAction: 'INITIATED'
      };
    case GOTO_ACTION_FROM_VIEW_EXTERNAL_TRANSFERS:
      return {
        ...state,
        perform: action.perform,
        inventoryRtnId:action.returnId,
        rmaNumber:action.rmaNumber,
        rmaAction:action.status
      }
    case SET_USER_INFO:
      return {
        ...state,
        userInfo:action.userInfo
      }    
    case UPDATE_PARTS_DETAILS:
        return {
          ...state,
          partDetailsExternalTransfers:action.partDetailsExternalTransfers
        } 
    case UPDATE_RMA_ACTION:
        return {
          ...state,
          rmaAction: action.status
        };
    case CLEAR_PERFORM:
        return {
          ...state,
          perform: ''
        }
    case UPDATE_ISLOADING:
        return {
          ...state,
          isLoading : action.isLoading
        }          
    default:
      return state;
  }
};
