import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DashTable from "../../../PreviousTransfers/Dashboard/DashTable/DashTable";
import DashTableFilter from "../DashTableFilter/DashTableFilter";
import "./DashMain.scss";
import "../../../../styles/_reactTable-overrides.scss";


class DashMain extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { resumeable, rowSelected, tableContent } = this.props;
        return (
            <main className="dash-main" role="main" id="main-content">
                <DashTableFilter />
                <DashTable
                    orders={tableContent}
                    rowSelected={rowSelected}
                    resumeable={resumeable}
                />
                {/* <div className="dash-main-bttn-cntnr">
                    <DashSidebarButton
                        className="width-50"
                        disable={false}
                        title="Dashboard"
                        onClick
                    />
                </div> */}
            </main>
        );
    }
}

const mapStateToProps = ({ }) => {
    return {
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
        },
        dispatch
    );


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashMain);