import React, { memo } from 'react';
import './PreviousTransfersModal.scss';
import CloseIcon from './icon-close.svg';

const PreviousTransfersModal = ({ buttons, closeModal, isLoader, message, title }) => {
  return (
    <div className="modal">
      <div className="modal-in">
        {!isLoader ? (
          <>
            {typeof closeModal === 'function' && (
              <button onClick={closeModal} className="modal-close-btn">
                <img src={CloseIcon} className="workflow-header-btn__icon" alt="Close Icon" />
              </button>
            )}
            <div className="modal-main">
              <h1 className="modal-title">{title}</h1>
              <div className="modal-message">{message}</div>
              <div className="modal-buttons">{buttons}</div>
            </div>
          </>
        ) : (
          <div className="modal-main">
            <h1 className="modal-title">{title}</h1>
            <div className="modal-loader">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PreviousTransfersModal.defaultProps = {
  isLoader: false
};

export default memo(PreviousTransfersModal);
