import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Dashboard from "./Dashboard/Dashboard";

class ViewExternalTransfers extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Dashboard />;
  }
}

export default ViewExternalTransfers;
