import React, { memo } from "react";
import ReactTable from "react-table";
import { connect } from "react-redux";
import "../../../styles/_reactTable-overrides.scss";
import "./TransferedTable.scss";

const TransferedTable = ({ serializedPartList, erroredSerialNumbers, resumeable, rowSelected, erroredArray }) => {
  console.info('erroredserials' +`${erroredSerialNumbers}`);
  erroredArray.forEach(element => {
     if(element.error){
       if(element.error === "Could not find the entity"){
          element.error = "The Serial Number was not found the origin location"
       }
       if(element.error.includes("quantity can't been updated")){
        element.error = "Part quantity can not be updated"
       }
     }
  });
  const columns = [
    {
      Header: "Serial Number",
      accessor: 'serialNumber'
      // Cell: props => <span className="orderID">{props.value}</span>
    },
    {
      Header: "Previous Location",
      accessor: 'inventoryLocationIdPrevious', //'previousDescription'
      Cell: props => {
        return props.value;
      }
    },
    {
      Header: "Current Location",
      accessor: 'inventoryLocationId', //'description'
      Cell: props => {
        return props.value;
      }
    }
  ];
  const erroredColumns = [
    {
      Header: "Serial Number",
      accessor: 'serialNumber'
      // Cell: props => <span className="orderID">{props.value}</span>
    },
    {
      Header: "Error",
      accessor: 'error' //'previousDescription'
      // Cell: props => {
      //   return props.value;
      // }
    }
    // {
    //   Header: "Current Location",
    //   accessor: 'inventoryLocationId', //'description'
    //   Cell: props => {
    //     return props.value;
    //   }
    // }
  ];  
  return (
    <div>
      {serializedPartList.length > 0 &&
      <div>
      <label>Successful Transfers:</label> 
       <ReactTable
        data={serializedPartList}
        pageSize={serializedPartList.length}
        columns={columns}
        getTrProps={(state, rowInfo, column) => {
          return {
            onClick: e => {
              if (resumeable) rowSelected(rowInfo.original);
            }
          };
        }}
        minRows={0}
        showPageSizeOptions={false}
        showPagination={false}
      />
      </div>}
      {erroredArray.length > 0 &&
      <div>
      <label>Unsuccessful Transfers:</label> 
      <label>The following Serial Numbers were NOT successfully transferred. Please make sure they are valid and have been received at the location and try again!</label> 
       <ReactTable
        data={erroredArray}
        pageSize={erroredArray.length}
        columns={erroredColumns}
        className="erroredSerialNumbers__input-group"
        getTrProps={(state, rowInfo, column) => {
          return {
            onClick: e => {
              if (resumeable) rowSelected(rowInfo.original);
            }
          };
        }}
        minRows={0}
        showPageSizeOptions={false}
        showPagination={false}
      />
      </div>}
    </div>
  );
};

const mapStateToProps = ({ inventoryTransfer }) => {
  const { serializedPartList, erroredSerialNumbers, erroredArray } = inventoryTransfer;
  return {
    serializedPartList,
    erroredSerialNumbers,
    erroredArray
  };
};

export default connect(
  mapStateToProps,
  null
)(memo(TransferedTable));
