import {
    SAVE_DESTINATION_SP_PART_LOCATION_ID,
    SAVE_SOURCE_SP_PART_LOCATION_ID,
    SAVE_IS_COMING_IN,
    SAVE_SUB_LOCATION_PARENT_PART_LOCATION_ID
} from "../actions/ReverseTransferActions";



const initialState = {
    spDestinationPartLocationIdReverse: "",
    spSourcePartLocationIdReverse: "",
    isComingIn: false,
    subLocationParentPartLocationId: ""

};

export default (state = initialState, action) => {
    switch (action.type) {
        case SAVE_DESTINATION_SP_PART_LOCATION_ID:
            return {
                ...state,
                spDestinationPartLocationIdReverse: action.spDestinationPartLocationIdReverse
            };
        case SAVE_SOURCE_SP_PART_LOCATION_ID:
            return {
                ...state,
                spSourcePartLocationIdReverse: action.spSourcePartLocationIdReverse
            };
        case SAVE_IS_COMING_IN:
            return {
                ...state,
                isComingIn: action.isComingIn
            };
        case SAVE_SUB_LOCATION_PARENT_PART_LOCATION_ID:
                return {
                    ...state,
                    subLocationParentPartLocationId: action.subLocationParentPartLocationId
                };
        default:
            return state;
    }
}