import React, { memo } from "react";
import "./Header.scss";
import logo from "./asurion_logo_black.png";

const Header = () => (
  <header className="dash-header" role="banner">
    <div className="dash-header__left">
      <img className="dash-app-logo" src={logo} alt="Asurion Logo" />
    </div>
    <div className="dash-header__right" />
  </header>
);

export default memo(Header);
