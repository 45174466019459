import React, { Component } from "react";
import TransferedMain from "./TransferedMain/TransferedMain";
import "./CompleteTransfer.scss";

class CompleteTransfer extends Component {
  render() {
    return (
      <div className="completetransfer">
        <TransferedMain />
      </div>
    );
  }
}

export default CompleteTransfer;
