import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DashMain from "../../PreviousTransfers/Dashboard/DashMain/DashMain";
import DashSide from "../../PreviousTransfers/Dashboard/DashSide/DashSide";
import DashWidget from '../Dashboard/DashWidget/DashWidget';
import { saveIsComingInForPreviousTransfers, disableViewPreviousTransfers } from '../../../actions/previousTransferActions';
import { setTransferCompleteToFalse } from '../../../actions/inventoryTransferActions';
import DatePicker from "../Dashboard/DatePicker/DatePicker";
import "./Dashboard.scss";
import DashSidebarButton from "../Dashboard/DashSideBarButton/DashSideBarButton";

const legends = {
    Going_Out: 'Going Out',
    Coming_In: 'Coming In'
};

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentOrder: null,
            currentView: null,
            displayRowInfo: false,
            resumeable: false,
            tableView: false,
            isComingIn: null,
            dashWidgetChanged: false
        };
    this.dashboardOnClick = this.dashboardOnClick.bind(this);
    }

    dashboardOnClick(){
        this.props.setTransferCompleteToFalse();
        this.props.disableViewPreviousTransfers();
    }

    showData(dataType) {
        const { Going_Out, Coming_In } = legends;
        switch (dataType) {
            case Going_Out:
                this.setState({
                    currentView: dataType,
                    displayRowInfo: false,
                    resumeable: false,
                    tableView: true
                });
                this.setState(
                    {
                        isComingIn: false,
                        dashWidgetChanged: true
                    }, () => this.props.saveIsComingInForPreviousTransfers(this.state.isComingIn, this.state.currentView)
                )
                break;
            case Coming_In:
                this.setState({
                    currentView: dataType,
                    displayRowInfo: false,
                    resumeable: false,
                    tableView: true
                });
                this.setState(
                    {
                        isComingIn: true,
                        dashWidgetChanged: true
                    }, () => this.props.saveIsComingInForPreviousTransfers(this.state.isComingIn, this.state.currentView)
                )
                break;
            default:
                this.setState({
                    currentView: dataType,
                    displayRowInfo: false,
                    resumeable: false,
                    tableView: false
                });
        }
    }

    render() {
        const { Going_Out, Coming_In } = legends;
        const { currentOrder, currentView, displayRowInfo, tableView } = this.state;
        return (
            <div className="dashboard">
                <DashSide label="Which transfers would you like to see?">
                    <>
                        <DashWidget
                            title="Going Out"
                            count
                            onClick={() => this.showData(Going_Out)}
                            selected={currentView === Going_Out}
                        />
                        <DashWidget
                            title="Coming In"
                            count
                            onClick={() => this.showData(Coming_In)}
                            selected={currentView === Coming_In}
                        />
                    </>
                </DashSide>
                <div className=".dash-main-bttn-side" style={{ position: 'fixed', bottom: '117px', width: 'auto', display: 'inline-block' , textAlign: 'center' , left: '20px'}}>
                    <DashSidebarButton
                        disable={false}
                        title="Dashboard"
                        onClick={this.dashboardOnClick}
                    />
                </div>
                <DashMain />
            </div>
        );
    }
}

const mapStateToProps = ({ }) => {
    return {

    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            saveIsComingInForPreviousTransfers,
            setTransferCompleteToFalse,
            disableViewPreviousTransfers
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);