import {
  SAVE_SERVICE_PROVIDER_LOCATION_INFO,
  SAVE_SERVICE_PROVIDER_SUB_LOCATION_INFO,
  SAVE_SERVICE_PROVIDER_ID,
  SAVE_SERVICE_PROVIDER_LOCATION_ID,
  SAVE_PART_DETAILS,
  SAVE_SOURCE_SP_PART_LOCATION_ID,
  SAVE_DESTINATION_SP_PART_LOCATION_ID,
  SAVE_MOVE_DETAILS,
  TRANSFER_COMPLETE,
  SAVE_PART_DETAILS_FOR_311_GENERATION,
  START_NEW_TRANSFER,
  SAVE_ERRORED_PART_SERIAL_NUMBER_DETAILS,
  SAVE_SUCCESS_RESPONSE,
  HIDE_VALID,
  SAVE_ERRORED_ARRAY,
  UPDATE_PART_DETAILS,
  SET_TRANSFER_COMPLETE_TO_FALSE
} from "../actions/inventoryTransferActions";

const initialState = {
  moveDetail: [],
  nonErroredSerialNumbers: [],
  erroredSerialNumbers: [],
  erroredArray: [],
  partDetail: [],
  partDetails: [],
  partDetail311: [],
  partDetails311: [],
  serializedPartList: [],
  serviceProviderId: "",
  serviceProviderLocationId: "",
  serviceProviderLocationName: [],
  serviceProviderSubLocationName: [],
  spDestinationPartLocationId: "",
  spSourcePartLocationId: "",
  transferComplete: false,
  successMessage: "",
  showSuccess: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SERVICE_PROVIDER_LOCATION_INFO:
      return {
        ...state,
        serviceProviderLocationName: action.serviceProviderLocationName
      };
    case SAVE_SERVICE_PROVIDER_SUB_LOCATION_INFO:
      return {
        ...state,
        serviceProviderSubLocationName: action.serviceProviderSubLocationName
      };
    case SAVE_SERVICE_PROVIDER_ID:
      return { ...state, serviceProviderId: action.serviceProviderId };
    case SAVE_SERVICE_PROVIDER_LOCATION_ID:
      return {
        ...state,
        serviceProviderLocationId: action.serviceProviderLocationId
      };
    case SAVE_PART_DETAILS:
      return {
        ...state,
        partDetails: [
          ...state.partDetails,
          {
            serialNumber: action.partDetail[0].serialNumber,
            partNumber: action.partDetail[0].spPartMaster.partNumber
          }
        ]
      };
    case SAVE_SOURCE_SP_PART_LOCATION_ID:
      return {
        ...state,
        spSourcePartLocationId: action.spSourcePartLocationId
      };
    case SAVE_DESTINATION_SP_PART_LOCATION_ID:
      return {
        ...state,
        spDestinationPartLocationId: action.spDestinationPartLocationId
      };
    case SAVE_MOVE_DETAILS:
      console.info(action.nonErroredSerialNumbers.serializedPartList);
      return {
        ...state,
        serializedPartList: action.nonErroredSerialNumbers.serializedPartList
      };
    case SAVE_ERRORED_PART_SERIAL_NUMBER_DETAILS:
      console.info(action.erroredSerialNumbers.serializedPartList);
      return {
        ...state,
        erroredSerialNumbers: action.erroredSerialNumbers.serializedPartList
      }
    case SAVE_ERRORED_ARRAY:
      return {
        ...state,
        erroredArray: action.erroredArray
      }
    case TRANSFER_COMPLETE:
      return {
        ...state,
        transferComplete: true
      };
    case SET_TRANSFER_COMPLETE_TO_FALSE:
      return {
         ...initialState 
      }
    case SAVE_PART_DETAILS_FOR_311_GENERATION:
      console.info(action.partDetail311);
      return {
        ...state,
        partDetails311: [
          ...state.partDetails311,
          {
            serialNumber: action.partDetail311[0].serialNumber,
            partNumber: action.partDetail311[0].spPartMaster.partNumber,
            spPartInventoryId: action.partDetail311[0].spPartInventory.locationPartInfoId,
            valuationType: action.partDetail311[0].partClassificationType,
            statusCode: action.partDetail311[0].statusCode
          }
        ]
      };
    case SAVE_SUCCESS_RESPONSE:
      return {
        ...state,
        showSuccess: true,
        successMessage: "Valid Serial Number. Success!"
      }
    case HIDE_VALID:
      return {
        ...state,
        showSuccess: false,
        successMessage: ""
      }
    case START_NEW_TRANSFER:
      return { ...initialState }
    case UPDATE_PART_DETAILS:
      let index = action.index._index;
      return {
        ...state,
        partDetails: 
        state.partDetails.filter((_, i) => i !== index),
        partDetails311: state.partDetails311.filter((_, i) => i !== index)
      }
    default:
      return state;
  }
};
