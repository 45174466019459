import {
  GET_INITIAL_STATE,
  SAVE_SELECTED_ACTION,
  CLEAN_EXISTING_DATA,
  SAVE_SEARCH_SUCCESS_RESPONSE,
  SET_TABLE_DATA,
  SET_TABLE_LOADING,
  SET_TABLE_DATA_AFTER_ACTION_SELECTION
} from "../actions/viewExternalTransferActions";

const initialState = {
  tableData:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TABLE_DATA:
      return {
        ...state,
        tableData: action.response.map(data => {
          let selected='INITIATED';
          if(data.status==='INITIATED') 
              selected = 'PRINT_PACKING_LIST';
          else if(data.status==='PKNG_LST_CREATED') 
              selected = 'REQUEST_LABEL'; 
          else if(data.status==='LABEL_REQUESTED'||data.status==='COMPLETED'||data.status==='CLOSED') 
              selected = 'VIEW';       
          else if(data.status==='LABEL_GENERATED')
              selected = 'PRINT_LABEL'
          else {selected = 'VIEW'; }    
          data = { ...data, selected: selected };
          return data
        }),
        tableLoadingOn: false
      };
    case SET_TABLE_DATA_AFTER_ACTION_SELECTION:
      return{
        ...state,
        tableData:action.data
      }  
    case SET_TABLE_LOADING:
      return {
        ...state,
        tableData: [],
        tableLoadingOn: true
      };  
    case CLEAN_EXISTING_DATA:
      return {
        ...initialState
      };
    case SAVE_SEARCH_SUCCESS_RESPONSE:
      return {
        ...state,
        showSuccessVT: true,
        successMessageVT: "Valid RMA Number !"
      };      
    case SAVE_SELECTED_ACTION:
      return {
        ...state,
        selectedAction: action.selectedAction
      };
    case GET_INITIAL_STATE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
