import React, { memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PrimaryInput from "../../../../Commons/PrimaryInput/PrimaryInput";
import './DashSearch.scss';
import {Typography} from 'antd';
import { getPartSerialNumberDetailsExternalTransfers } from "../../../../../actions/ExternalTransferActions"
const { Title } = Typography;

const DashSearch = props => (
    <div className="dash-search-container" style={{width:'100%'}}>
        <Title level={4}>What inventory would you like to use?</Title>
        <div className="dash-search">
            <label htmlFor="search" className="dash-search__label">
                Search orders
            </label>
            <PrimaryInput
                nextStep={() => console.info("Scanning")}
                onSubmit={serialNumber => props.getPartSerialNumberDetailsExternalTransfers(props.partDetailsExternalTransfers,props.serviceProviderIdExternalTransfers, `${props.userInfo.userLocation}`, serialNumber, props.inventoryRtnId, props.userId)}
                placeholder="Scan or enter serial number"
                rules='required|SerialNumber'
                title="Serial Number"
                type="Serial number"
            />
        </div>
    </div>
);

const mapStateToProps = ({ externalTransfer }) => {
    const { serviceProviderIdExternalTransfers, serviceProviderLocationIdExternalTransfers, inventoryRtnId, userId , userInfo,partDetailsExternalTransfers} = externalTransfer;
    return {
        serviceProviderIdExternalTransfers,
        serviceProviderLocationIdExternalTransfers,
        inventoryRtnId,
        userId,
        userInfo,
        partDetailsExternalTransfers
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getPartSerialNumberDetailsExternalTransfers
        }, dispatch
    )

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(DashSearch));
