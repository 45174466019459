import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DashMain from "./DashMain/DashMain";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <DashMain />
      </div>
    );
  }
}

export default Dashboard;
