import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Input } from 'antd';
import "./DashInput.scss";

class DashInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      placeholder: this.props.placeholder,
      valid: false,
      value: ""
    };
    this.validator = new SimpleReactValidator({
      validators: {
        ReferenceNumber: {
          message: "Invalid Reference Number!",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(val, /^[a-zA-Z0-9]+$/i) &&
              params.indexOf(val) === -1
            );
          },
          required: true
        }
      }
    });
    this.handleChange = this.handleChange.bind(this);
    this.setToOriginal = this.setToOriginal.bind(this);
    this.validateChange = this.validateChange.bind(this);
  }

  handleChange(event) {
    this.setState(
      {
        value: event.target.value
      },
      () => this.validateChange(this.state.value)
    );
  }

  setToOriginal() {
    this.setState({
      error: false,
      placeholder: this.props.placeholder,
      valid: false,
      value: ""
    });
  }

  validateChange = value => {
    if (this.validator.allValid()) {
      this.setState({
        valid: true
      });
      if (this.props.onSubmit) {
        this.props.onSubmit(value);
      }
    } else {
      this.setState({
        error: true,
        placeholder: this.validator.getErrorMessages()[this.props.title],
        value: ""
      });
      this.forceUpdate();
      setTimeout(
        function() {
          this.setToOriginal();
        }.bind(this),
        1000
      );
    }
  };

  render() {
    return (
      <div>
        <Input
          type="text"
          value={this.state.value}
          onChange={this.handleChange}
          className={"reference-number"}
          id="primary-field"
          placeholder={this.state.placeholder}
          autoFocus
          autoComplete="off"
        />
        {this.validator.message(
          this.props.title,
          this.state.value,
          this.props.rules
        )}
      </div>
    );
  }
}

export default DashInput;
