import React, { memo } from "react";
import { connect } from "react-redux";
import DropDown from "../../Commons/DropDown/DropDown";

const TransferDropDown = ({
  isComingIn,
  onComingFromChange,
  onGoingToChange,
  serviceProviderLocationName,
  serviceProviderSubLocationName,
  subLocationParentPartLocationId
}) => {

  let serviceProviderLocationNamesFiltered = null;
  let serviceProviderLocationNames = null;
  let serviceProviderSubLocationNames = null;

  if (isComingIn) {
    const serviceProviderLocationNamesReverse = serviceProviderLocationName.filter(val => {
      if (val.SP_PART_LOCATION_ID === subLocationParentPartLocationId) {
        return val;
      }
    }
    );

    serviceProviderLocationNamesFiltered = serviceProviderLocationNamesReverse.map(
      (location, key) => {
        return (
          <option key={key} value={location.SERVICE_PROVIDER_LOCATION_ID}>
            {location.DESCRIPTION}
          </option>
        );
      }
    )
  }

  if (!isComingIn) {
    serviceProviderLocationNames = serviceProviderLocationName.map(
      (location, key) => {
        return (
          <option key={key} value={location.SERVICE_PROVIDER_LOCATION_ID}>
            {location.DESCRIPTION}
          </option>
        );
      }
    );
  }

  if (serviceProviderSubLocationName !== null && serviceProviderSubLocationName !== undefined) {
    serviceProviderSubLocationNames = serviceProviderSubLocationName.map(
      (subLocation, key) => {
        return (
          <option key={key} value={subLocation.SP_PART_LOCATION_ID}>
            {subLocation.DESCRIPTION}
          </option>
        );
      }
    );
  }

  return (
    isComingIn ?
      <>
        <DropDown
          id="cf_dd"
          label="Coming from"
          name="serviceProviderSubLocationDropDown"
          onChange={onComingFromChange}
        >
          <option value="select">Select</option>
          {serviceProviderSubLocationNames}
        </DropDown>
        <DropDown
          id="gt_dd"
          onChange={onGoingToChange}
          label="Going to"
          name="serviceProviderLocationDropDown"
        >
          <option value="select">Select</option>
          {serviceProviderLocationNamesFiltered}
        </DropDown>

      </>
      :
      <>
        <DropDown
          id="cf_dd"
          label="Coming from"
          name="serviceProviderLocationDropDown"
          onChange={onComingFromChange}
        >
          <option value="select">Select</option>
          {serviceProviderLocationNames}
        </DropDown>
        <DropDown
          id="gt_dd"
          onChange={onGoingToChange}
          label="Going to"
          name="serviceProviderSubLocationDropDown"
        >
          <option value="select">Select</option>
          {serviceProviderSubLocationNames}
        </DropDown>
      </>
  );
};

const mapStateToProps = ({ inventoryTransfer, inventoryReverseTransfer }) => {
  const { serviceProviderLocationName, serviceProviderSubLocationName } = inventoryTransfer;
  const { subLocationParentPartLocationId } = inventoryReverseTransfer;
  return {
    serviceProviderLocationName,
    serviceProviderSubLocationName,
    subLocationParentPartLocationId
  };
};

export default connect(
  mapStateToProps,
  null
)(memo(TransferDropDown));
