import axios from "axios";
import config from "../config";
import uuid from "uuid";

export const uriName = "ift-inventoryfacilities";
export const eventsUriName = "ift-eventsapi";
export const shippingGwUriName = "osg-operationsshippinggw";
const sessionId= uuid.v4();

const apiMapping = {
  GET: axios.get,
  POST: axios.post
};

export const userInfo = {
  companyId: 0,
  token: "",
  refreshToken: "",
  userName: "",
  apiKey: "",
  sessionId: "",
  serviceProviderId: 0,
  serviceProviderLocationId: 0
};

const getBaseUri = baseUrl => endpoint => `${baseUrl}${endpoint}`;

const getUrl = name => {
  const endpoints = config.API.endpoints.filter(
    ep => ep.name.toLowerCase() === name.toLowerCase()
  );
  userInfo.apiKey = endpoints[0].apikey;
  return getBaseUri(
    `${endpoints[0].endpoint}${
      endpoints[0].suburi.length > 0 ? endpoints[0].suburi : ""
    }`
  );
};

const getHeaders = (uri, customHeaders) => {
  const endpoints = config.API.endpoints.filter(
    ep => ep.name.toLowerCase() === uri
  );
  userInfo.apiKey = endpoints[0].apikey;
  const commonHeaders = {
    "Content-Type": "application/json",
    "x-api-key": userInfo.apiKey,
    Accept: "application/json",
    Authorization: `Bearer ${userInfo.token}`,
    companyId: userInfo.serviceProviderId,
    correlationId: uuid.v4(),
    sender: "servicebench",
    sessionId: sessionId,
    username: userInfo.userName
  };
  return { ...commonHeaders, ...customHeaders };
};

const executeApi = (resolve, reject) => (
  apiName,
  endpoint,
  bodyContent,
  config
) => {
  const { method = "GET", body = null } = bodyContent;
  const arrayOfData = [getUrl(apiName)(endpoint), body, config].filter(
    item => item !== null
  );
  console.log(arrayOfData);
  apiMapping[method]
    .apply(axios, arrayOfData)
    .then(res => manageResponse(resolve, reject, res))
    .catch(err => {
      const errorToReturn = manageError(err);
      reject(errorToReturn);
    });
};

const manageResponse = (resolve, reject, res) =>
  res.status === 200
    ? resolve(res.data)
    : reject("Error occurred while getting the response");

const manageError = payload => {
  console.log(payload);
  if (payload.response && payload.response.status !== 200) {
    if (payload.response.status !== 400 && payload.response.statusText !== "")
      return buildErrorMessage(
        payload.response.status,
        payload.response.statusText
      );
    else if (payload.response.data && payload.response.data.Error) {
      const { Code, Message } = payload.response.data.Error;
      return buildErrorMessage(Code, Message);
    } else if (payload.response.data.errors) {
      const { code, Message } = payload.response.data.errors[0];
      return buildErrorMessage(code, Message);
    }
  } else
    return buildErrorMessage(
      "Server Issue",
      "Server Issue : Please contact system administrator"
    );
};

const buildErrorMessage = (code, message) => ({
  Error: {
    Code: `${code}`,
    Message: message
  }
});

export const Get = (apiName, path, headers) => {
  return new Promise((resolve, reject) => {
    const custom_headers = {
      headers: headers ? headers : getHeaders(apiName),
      data: {} // Needed for passing Content-Type in headers
    };
    executeApi(resolve, reject)(apiName, path, {}, custom_headers);
  });
};

export const Post = (apiName, path, data) => {
  return new Promise((resolve, reject) => {
    const content = {
      body: { ...data },
      method: "POST"
    };
    const custom_headers = {
      headers: getHeaders(apiName)
    };
    executeApi(resolve, reject)(apiName, path, content, custom_headers);
  });
};
