import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DashSearch from "../DashSearch/DashSearch";
import DashTable from "../DashTable/DashTable";
import { Result,Modal,message,Button, Row, Layout, Space, Steps, Divider, Typography } from 'antd'
import {
  navigateToViewExternalTransfers,
  sendShippingLabelRequestToSNSQueue,
  hideDeleteCapability,
  disableScan,
  printPackingDetails,
  completeTransfer,
  clearPerformAction,
  navigateToPreviousExternalTransfersFromExternalTransfersDashboard,
  startNewExternalTransfer  ,
  getShippingLabel
} from "../../../../../actions/ExternalTransferActions";
import {
  shippingLabelDetails,
  cleanExistingData,
  getInitialState
} from "../../../../../actions/viewExternalTransferActions";
const {Footer, Content}= Layout;
const { Title, Text } = Typography;


class RMADashMain extends Component {
  constructor(props) {
    super(props);
    this.packingListOnClick = this.packingListOnClick.bind(this);
    this.shippingLabelOnClick = this.shippingLabelOnClick.bind(this);
    this.completeOnClick = this.completeOnClick.bind(this);
    this.packingListData = this.packingListData.bind(this);
    this.printShippingLabel= this.printShippingLabel.bind(this);
    this.state={
      modalVisible:false,
      ShipModal:false,
      printModal:false 
    }
  }

  async packingListOnClick() {
    if (this.props.rmaAction === "INITIATED") {
      await this.props.printPackingDetails(this.props.inventoryRtnId);
      await this.props.hideDeleteCapability();
    }
    this.setState({printModal:true});
    
  }

  async shippingLabelOnClick() {
    let message=await this.props.sendShippingLabelRequestToSNSQueue(
      this.props.serviceProviderIdExternalTransfers,
      this.props.inventoryRtnId,
      this.props.partDetailsExternalTransfers     
    );
    console.log(message);
    return message;
  }
  async completeOnClick() {
    let message=await this.props.completeTransfer(this.props.inventoryRtnId);
    if(message.message==='Status updated successfully!'){
      this.setState({ modalVisible:true})
    }
  }
  
  async componentWillMount(){
    console.log('component will mount');
    if(this.props.rmaNumber && this.props.inventoryRtnId && this.props.rmaAction && this.props.perform){
      if(this.props.perform==='PRINT_PACKING_LIST' && this.props.rmaAction === "INITIATED"){
        await this.props.printPackingDetails(this.props.inventoryRtnId);
      }
      if(this.props.perform==='REQUEST_LABEL' && this.props.rmaAction === "PKNG_LST_CREATED"){
        console.log('label being requested')
        let mess= await this.shippingLabelOnClick();
          if (mess && mess.type) {
          if (mess.type === 'success') {
            message.success(mess.message, 3)
          }
          if (mess.type === 'error') {
            message.error(mess.message, 3)
          }
        }
      }
      if(this.props.perform==='COMPLETE' && this.props.rmaAction === "LABEL_GENERATED"){
        let message=await this.props.completeTransfer(this.props.inventoryRtnId);
        if(message.message==='Status updated successfully!'){
        this.setState({ modalVisible:true})
    }
      }
    };    
  }

  async componentDidMount(){   
    console.log('component mounted');
    if(this.props.rmaNumber && this.props.inventoryRtnId && this.props.rmaAction && this.props.perform){  
      if(this.props.perform==='PRINT_PACKING_LIST'){
        this.setState({printModal:true});
      }
      if(this.props.perform==='PRINT_LABEL' && this.props.rmaAction === "LABEL_GENERATED"){
        let url= await this.props.getShippingLabel(this.props.serviceProviderIdExternalTransfers,this.props.inventoryRtnId,this.props.partDetailsExternalTransfers);
        this.setState({url:url,ShipModal:true});
      }

    } 
    this.props.clearPerformAction();
  }
  
  printShippingLabel(url){   
    if (url) {
      console.log(url)
      let frame1 = document.createElement('iframe');
      frame1.name = "frame1";
      document.body.appendChild(frame1);
      let frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
      frameDoc.document.open();
      frameDoc.document.write(`<html><head></head><body><img style="width:800px" src="${url}"/></body></html>'`);
      frameDoc.document.close();
      setTimeout(function () {
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
        document.body.removeChild(frame1);
      }, 500);
    }
  }

  packingListData(){
    console.log("print button pressed")
    let frame1 = document.createElement('iframe');
    frame1.name = "frame1";
    frame1.style.position = "absolute";
    frame1.style.top = "-1000000px";
    document.body.appendChild(frame1);
    let frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write('<html><head><title>Packing List</title>');
    frameDoc.document.write('</head><body>');
    let RMAAddressDiv = `<div>
    <h3>FROM:</h3>
    <pre>
    ${this.props.selectedFromAddress.name}
    ${this.props.selectedFromAddress.addressLine1}
    ${this.props.selectedFromAddress.addressCity}
    ${this.props.selectedFromAddress.addressState}
    ${this.props.selectedFromAddress.addressZip}
    </pre>
    <br/>
    <h3>TO:</h3>
    <pre>
    ${this.props.selectedToAddress.name}
    ${this.props.selectedToAddress.addressLine1}
    ${this.props.selectedToAddress.addressCity}
    ${this.props.selectedToAddress.addressState}
    ${this.props.selectedToAddress.addressZip}
    </pre>
    <br/>
    <pre>
    Return ID:${this.props.rmaNumber}
    </pre>
    <br/>
    </div>`
    let Tablediv=`<div><table style="width:100%;border: 1px solid black; border-collapse: collapse">
    <tr >
    <th style="border: 1px solid black;">Part Number</th>
    <th style="border: 1px solid black;">Part Serial</th>
  </tr>`;
  this.props.partDetailsExternalTransfers.forEach(element => {
    Tablediv = Tablediv +
    `<tr>
    <td style="border: 1px solid black;">${element.partNumber}</td>
    <td style="border: 1px solid black;">${element.serialNumber}</td>
  </tr>`
  });
  
    Tablediv = Tablediv + `</table></div>`;
    let content = RMAAddressDiv + Tablediv;
    frameDoc.document.write(content);
    frameDoc.document.write('</body></html>');
    frameDoc.document.close();
    setTimeout(function () {
      window.frames["frame1"].focus();
      window.frames["frame1"].print();
      document.body.removeChild(frame1);
    }, 500);
    console.log(this.props.partDetailsExternalTransfers)
    console.log("print button pressed")
  }

  render() {
    return (
      <>
        <Layout>
          <Modal visible={this.state.modalVisible} closable={false}  footer={[
            <Button key="back" type="primary" onClick={()=>this.setState({modalVisible:false},()=>{this.props.startNewExternalTransfer();this.props.getInitialState();})}> Start New RMA</Button>,
            <Button key="submit" type="primary" onClick={()=>{ this.props.navigateToPreviousExternalTransfersFromExternalTransfersDashboard();
              this.props.cleanExistingData();}}> Previous External Transfers</Button>
          ]}>
            <Result status="success" title="Successfully completed RMA"/>
          </Modal>
          <Modal bodyStyle={{maxHeight:'250px','overflow-y':'auto'}} key="PackListModal" visible={this.state.printModal} closable={false} footer={[
            <Button key="back" type="primary" onClick={() => this.setState({ printModal: false })}> Close</Button>,
            <Button key="submit" type="primary" onClick={() => {this.packingListData()}}> Print</Button>
          ]}>
            <div>
              <Space direction="vertical" >
                <h3>FROM:</h3>
                <Text>{this.props.selectedFromAddress.name}</Text>
                <Text>{this.props.selectedFromAddress.addressLine1}</Text>
                <Text>{this.props.selectedFromAddress.addressCity}</Text>
                <Text>{this.props.selectedFromAddress.addressState}</Text>
                <Text>{this.props.selectedFromAddress.addressZip}</Text>
                <br />
                <h3>TO:</h3>
                <Text>{this.props.selectedToAddress.name}</Text>
                <Text>{this.props.selectedToAddress.addressLine1}</Text>
                <Text>{this.props.selectedToAddress.addressCity}</Text>
                <Text>{this.props.selectedToAddress.addressState}</Text>
                <Text>{this.props.selectedToAddress.addressZip}</Text>
                <br />
                <div><h3>Return ID :</h3><Text>{this.props.rmaNumber}</Text></div>
                <table style={{width:'100%',border: '1px solid black', borderCollapse: 'collapse'}}>
                  <tr >
                    <th style={{border: '1px solid black'}}>Part Number</th>
                    <th style={{border: '1px solid black'}}>Part Serial</th>
                  </tr>
                  {this.props.partDetailsExternalTransfers.map(value => {
                      return <tr>
                        <td style={{border: '1px solid black'}}>{value.partNumber}</td>
                        <td style={{border: '1px solid black'}}>{value.serialNumber}</td>
                      </tr>
                  })}
                </table>
              </Space>
            </div>          
          </Modal>
          <Modal centered={true} size='large' key="ShippingLabelModal" visible={this.state.url&&this.state.ShipModal} closable={false}  footer={[
            <Button key="back" type="primary" onClick={()=>{this.setState({ShipModal:false})}}> Close</Button>,
            <Button key="submit" type="primary" onClick={()=>{this.printShippingLabel(this.state.url);this.setState({ShipModal:false})}}>Print</Button>
          ]}>
            <div id="shipLabel">
            <img src={this.state.url}  style={{'maxHeight':'200px', 'display': 'block','marginLeft': 'auto', 'marginRight': 'auto'}}></img>
            </div>
            
          </Modal>
          <Content style={{ backgroundColor: 'white' }}>
            <Row> <DashSearch /></Row>
            <Row> <DashTable /></Row>
          </Content>
          <Footer style={{ backgroundColor: 'white' }}>
            <Row>
              <Space>
                {(this.props.rmaAction && ["LABEL_REQUESTED", "LABEL_GENERATED", "INITIATED", "PKNG_LST_CREATED", "COMPLETED"].includes(this.props.rmaAction) && this.props.partDetailsExternalTransfers) 
                && this.props.partDetailsExternalTransfers.length>0? <Button key="" type="primary" onClick={() => this.packingListOnClick()}>PACKING LIST</Button> : ''}
                {(this.props.rmaAction && ["PKNG_LST_CREATED"].includes(this.props.rmaAction)) ? <Button key="" type="primary" onClick={() => {
                   this.shippingLabelOnClick().then((mess)=>{ 
                    console.log(mess);
                    if (mess && mess.type) {
                    if (mess.type === 'success') {
                      message.success(mess.message, 3)
                    }
                    if (mess.type === 'error') {
                      message.error(mess.message, 3)
                    }
                  }});}}>REQUEST SHIPPING LABEL</Button> : ''}
                {(this.props.rmaAction && ["LABEL_GENERATED", "COMPLETED"].includes(this.props.rmaAction)) ? <Button key="" type="primary" floated='left' 
                onClick={async()=>{let url= await this.props.getShippingLabel(this.props.serviceProviderIdExternalTransfers,this.props.inventoryRtnId,this.props.partDetailsExternalTransfers );
                  this.printShippingLabel(url);}}>SHIPPING LABEL</Button> : ''}
                {(this.props.rmaAction && ["LABEL_GENERATED"].includes(this.props.rmaAction)) ? <Button key="" type="primary" style={{ float: "right" }} onClick={() => this.completeOnClick()}>COMPLETE</Button> : ''}
              </Space>
            </Row>
          </Footer>
        </Layout>
      </>
    );
  }
}

const mapStateToProps = ({ externalTransfer, viewExternalTransfer }) => {
  const {
    serviceProviderIdExternalTransfers,
    labelGenerationResponse,
    inventoryRtnId,
    rmaNumber,
    partDetailsExternalTransfers,
    rmaAction,
    selectedToAddress,
    selectedFromAddress,
    perform
  } = externalTransfer;
  return {
    serviceProviderIdExternalTransfers,
    labelGenerationResponse,
    inventoryRtnId,
    rmaNumber,
    partDetailsExternalTransfers,
    rmaAction,
    selectedToAddress,
    selectedFromAddress,
    perform
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      navigateToViewExternalTransfers,
      sendShippingLabelRequestToSNSQueue,
      hideDeleteCapability,
      printPackingDetails,
      completeTransfer,
      disableScan,
      clearPerformAction,
      navigateToPreviousExternalTransfersFromExternalTransfersDashboard,
      cleanExistingData,
      startNewExternalTransfer,
      getInitialState,
      getShippingLabel
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RMADashMain);
