import React, { memo } from "react";
import "./RadioButton.scss";

const RadioButton = ({ id, label, name, checkedValue, onChangeValue }) => (
  <label htmlFor={id} className="radio">
    <input type="radio" name={name} id={id} className="hidden" checked={checkedValue} onChange={onChangeValue}/>
    <span className="label" />
    {label}
  </label>
);

export default memo(RadioButton);
