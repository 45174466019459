import { Get, Post, uriName, shippingGwUriName } from "../utilities/apiUtility";
import { userInfo } from "../utilities/apiUtility";
import { parseJwt } from "../utilities/genericUtility";
import { hasError, showErrorMessage, showErrorMessageET, showGroupErrorMessages } from "./errorActions";
import { isEmpty } from "../utilities/genericUtility";
export const ENABLE_EXTERNAL_TRANSFERS = "ENABLE_EXTERNAL_TRANSFERS";
export const UPDATE_INVENTORY_RTN_ID = "UPDATE_INVENTORY_RTN_ID";
export const NAVIGATE_TO_EXTERNAL_TRANSFERS = "NAVIGATE_TO_EXTERNAL_TRANSFERS";
export const DISABLE_EXTERNAL_TRANSFERS = "DISABLE_EXTERNAL_TRANSFERS";
export const NAVIGATE_TO_VIEW_EXTERNAL_TRANSFERS = "NAVIGATE_TO_VIEW_EXTERNAL_TRANSFERS";
export const SAVE_PART_DETAILS_EXTERNAL_TRANSFERS =
  "SAVE_PART_DETAILS_EXTERNAL_TRANSFERS";
export const SAVE_RESPONSE = "SAVE_RESPONSE";
export const SAVE_RMA_ACTION_DETAILS = "SAVE_RMA_ACTION_DETAILS";
export const DISABLE_SCANNING_ABILITY = "DISABLE_SCANNING_ABILITY";
export const SET_EXTERNAL_TRANSFER_COMPLETE = "SET_EXTERNAL_TRANSFER_COMPLETE";
export const SAVE_USER_DEFAULT_LOCATION_ADDRESS_DETAILS =
  "SAVE_USER_DEFAULT_LOCATION_ADDRESS_DETAILS";
export const SAVE_DUMMY_DESTINATION_DETAILS = "SAVE_DUMMY_DESTINATION_DETAILS";
export const RMA_ALREADY_EXISTS = "RMA_ALREADY_EXISTS";
export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";
export const TOGGLE_RMA_VALIDATION = "TOGGLE_RMA_VALIDATION";
export const SAVE_SERVICE_PROVIDER_ID_EXTERNAL_TRANSFERS =
  "SAVE_SERVICE_PROVIDER_ID_EXTERNAL_TRANSFERS";
export const SAVE_APPLIED_DATES = "SAVE_APPLIED_DATES";
export const SAVE_SERVICE_PROVIDER_LOCATION_ID_EXTERNAL_TRANSFERS =
  "SAVE_SERVICE_PROVIDER_LOCATION_ID_EXTERNAL_TRANSFERS";
export const SAVE_INVENTORY_RTN_ID = "SAVE_INVENTORY_RTN_ID";
export const CLOSE_RMA_MODAL = "CLOSE_RMA_MODAL";
export const HIDE_VALID_ET = "HIDE_VALID_ET";
export const SET_SHOW_CALENDER_TO_TRUE = "SET_SHOW_CALENDER_TO_TRUE";
export const SAVE_ET_SUCCESS_RESPONSE = "SAVE_ET_SUCCESS_RESPONSE";
export const SAVE_SELECTED_TO_ADDRESS = "SAVE_SELECTED_TO_ADDRESS";
export const SAVE_SELECTED_FROM_ADDRESS = "SAVE_SELECTED_FROM_ADDRESS";
export const DELETE_SELECTED_PART = "DELETE_SELECTED_PART";
export const SAVE_DUMMY_PART_DETAILS_EXTERNAL_TRANSFERS ="SAVE_DUMMY_PART_DETAILS_EXTERNAL_TRANSFERS";
export const HIDE_RMA_SECTION = "HIDE_RMA_SECTION";
export const HIDE_DELETE_CAPABILITY = "HIDE_DELETE_CAPABILITY";
export const LABEL_GENERATION_RESPONSE = "LABEL_GENERATION_RESPONSE";
export const LAST_SEVEN_DAYS_EXTERNAL_TRANSFERS = "LAST_SEVEN_DAYS_EXTERNAL_TRANSFERS";
export const SAVE_APPLIED_DATES_EXT_TRANSFERS = "SAVE_APPLIED_DATES_EXT_TRANSFERS";
export const EXTERNAL_TRANSFERS_FOR_SELECTED_RANGE = "EXTERNAL_TRANSFERS_FOR_SELECTED_RANGE";
export const NAVIGATE_TO_HISTORIC_EXTERNAL_TRANSFERS = "NAVIGATE_TO_HISTORIC_EXTERNAL_TRANSFERS";
export const START_NEW_EXTERNAL_TRANSFER = "START_NEW_EXTERNAL_TRANSFER";
export const POPULATE_RMA_DETAILS = "POPULATE_RMA_DETAILS";
export const CLEAR_ALL_DATA = "CLEAR_ALL_DATA";
export const SAVE_RMA_RELATED_DETAILS = "SAVE_RMA_RELATED_DETAILS";
export const POPULATE_RMA_RELATED_ADDRESS_DETAILS = "POPULATE_RMA_RELATED_ADDRESS_DETAILS";
export const GET_INITIAL_STATE = "GET_INITIAL_STATE";
export const SAVE_RMA_ACTION="SAVE_RMA_ACTION";
export const UPDATE_PARTS_DETAILS="UPDATE_PARTS_DETAILS";
export const SET_USER_INFO="SET_USER_INFO";
export const UPDATE_RMA_ACTION="UPDATE_RMA_ACTION";
export const CLEAR_PERFORM="CLEAR_PERFORM"
export const UPDATE_ISLOADING="UPDATE_ISLOADING";
const SERVICE_ADMINISTRATOR_ID = "43";
const RETAILER_STORE_NUMBER = "500";
const CLIENT_OWNING_SYSTEM_CODE = "SERVICEBENCH";
let userDetails;

export const navigateToExternalTransfers = selected => async dispatch => {
  if (selected === 'rma') {
    const jwt = parseJwt(userInfo.token);
    userDetails = jwt.username.split("|");
    userInfo.companyId = userDetails[0];
    userInfo.userName = userDetails[1];
    const userAccountId = userInfo.userName;
    try {
      dispatch({type:UPDATE_ISLOADING,isLoading:true})
      const response1 = {};
      response1.destinationLocationAddress = [
        {
          name: "Verizon-1",
          addressLine1: "413 N JEFFERSON ST",
          addressLine2: null,
          addressCity: "KEARNEY",
          addressState: "MO",
          addressZip: "640608380",
          addressCountry: "US",
          serviceProviderLocationId: "63868095"
        },
        {
          name: "Verizon-2",
          addressLine1: "414 N JEFFERSON ST",
          addressLine2: null,
          addressCity: "KEARNQY",
          addressState: "MP",
          addressZip: "640608380",
          addressCountry: "US",
          serviceProviderLocationId: "63868096"
        }
      ];
      const response = await Get(
        uriName,
        `/v2/gateway/get?userAccountId=${userAccountId}&serviceAdministratorId=${SERVICE_ADMINISTRATOR_ID}`
      );
      console.log(response);
      dispatch(saveServiceProviderIdExternalTransfer(userDetails[0]));
      dispatch(saveUserDefaultLocationAddressDetails(response));
      dispatch(saveUserDetails(response.userInfo));
      dispatch(saveDummyDestinationDetails(response));
      dispatch(enableExternalTransfers());
      dispatch({type:SET_USER_INFO,userInfo:{userId:response.userInfo.userId,userLocation:response.userLocationAddressDetails[0].serviceProviderLocationId}});
      dispatch({type:UPDATE_ISLOADING,isLoading:false})
    } catch (error) {
      console.log(error);
    }
  }else if(selected === 'fsl'){
    dispatch({type:UPDATE_ISLOADING,isLoading:true})
    dispatch(disableExternalTransfers());
    dispatch(clearAllData());
    dispatch(getInitialState());
    dispatch({type:UPDATE_ISLOADING,isLoading:false})
  }
  console.log(` navigateToExternalTransfers in exttr actions executed`)
};

export const getPartSerialNumberDetailsExternalTransfers = (
  partDetailsExternalTransfers,
  serviceProviderIdExternalTransfers,
  serviceProviderLocationIdExternalTransfers,
  serialNumber,
  inventoryRtnId,
  userId
) => async dispatch => {
  try {
    let exists = await partExists(partDetailsExternalTransfers,serialNumber);
    if(!exists){
    const postData = {
      companyId: serviceProviderIdExternalTransfers,
      companyPhysicalLocationId: serviceProviderLocationIdExternalTransfers,
      serialNumber: serialNumber,
      inventoryRtnId: inventoryRtnId,
      userId: userId,
      lineNumber: null,
      partQuantity: 1,
      itemNotes: null,
      inventoryRtnItemReqId: null,
      inventoryRtnShipmentId: null,
      deleteSerial: false,
      rmaStatusChange: false,
      token: userInfo.token
    };
    const res = await Post(uriName, `/v2/gateway`, postData);
    console.log(res);
    if (hasError(res)) {
      dispatch(showErrorMessage("Unable to get the data from the server."));
    } else if (res && !isEmpty(res) && res.partSerialStatusCode !== undefined && res.partSerialStatusCode !== "PENDING_RETURN" && res.partSerialStatusCode !== "RETURN" && res.partSerialStatusCode !== "PENDING_RECEIVING") {
      dispatch(saveExternalTransfersPartDetails(res));
      dispatch(saveETSuccessResponse());
      setTimeout(() => dispatch({ type: HIDE_VALID_ET }), 1000);
    } if(res && !isEmpty(res) && res.partSerialStatusCode === "PENDING_RETURN"){
      dispatch(showErrorMessage("Serial is in PENDING_RETURN status. Cannot make transfer"));
    } if(res && !isEmpty(res) && res.partSerialStatusCode === "RETURN"){
      dispatch(showErrorMessage("Serial is in RETURN status. Cannot make transfer"));
    }
    if(res && !isEmpty(res) && res.partSerialStatusCode === "PENDING_RECEIVING"){
      dispatch(showErrorMessage("Serial is in PENDING_RECEIVING status. Cannot make transfer"));
    }
  }else{
    dispatch(showErrorMessage("Duplicate Serial"));
  }
    
  } catch (error) {
    if (hasError(error)) {
      dispatch(showErrorMessage("Invalid Serial Number. Please try again!"));
    }
  }
};

export const navigateToViewExternalTransfers = () => ({
  type: NAVIGATE_TO_VIEW_EXTERNAL_TRANSFERS
});

export const getInitialState = () => ({
  type: GET_INITIAL_STATE
});

export const updateInventoryRtnId = inventoryRtnId => ({
  type: UPDATE_INVENTORY_RTN_ID,
  inventoryRtnId
})

export const sendShippingLabelRequestToSNSQueue = (serviceProviderIdExternalTransfers, inventoryRtnId, partDetailsExternalTransfers) => async dispatch => {
  try {
    const postData = {
      retailerStoreNumber : RETAILER_STORE_NUMBER,
      serviceProviderId : serviceProviderIdExternalTransfers,
      inventoryRtnId : inventoryRtnId,
      partDetailsExternalTransfers : partDetailsExternalTransfers
    }
    const response = await Post(shippingGwUriName, `/v1/shippinglabelurl`, postData);
    console.log(response);
    let rmaAction='PKNG_LST_CREATED'
    let message={}
    if(response.gatewayResponse.meta.message==='SUCCESS'){
      rmaAction='LABEL_REQUESTED'
      message= {type:'success',message:'label request has been Accepted'}
    }else{
      message= {type:'error',message:'label request has been Accepted'}
    }
    dispatch(saveLabelGenerationResponse(rmaAction));
    return message;
  } catch (error) {
    console.log(error);
  }
};

export const getShippingLabel = (serviceProviderIdExternalTransfers, inventoryRtnId, partDetailsExternalTransfers) => async dispatch => {
  try {
    const postData = {
      retailerStoreNumber : RETAILER_STORE_NUMBER,
      serviceProviderId : serviceProviderIdExternalTransfers,
      inventoryRtnId : inventoryRtnId,
      partDetailsExternalTransfers : partDetailsExternalTransfers
    }
    const response = await Post(shippingGwUriName, `/v1/shippinglabelurl`, postData);
    return response.ShippinglabelUrl;
  } catch (error) {
    console.log(error);
  }
};

export const saveLabelGenerationResponse = (rmaAction) => ({
  type: LABEL_GENERATION_RESPONSE,
  rmaAction
});

export const saveExternalTransfersPartDetails = partDetailExternalTransfers => ({
  type: SAVE_PART_DETAILS_EXTERNAL_TRANSFERS,
  partDetailExternalTransfers
});


export const saveETSuccessResponse = () => ({
  type: SAVE_ET_SUCCESS_RESPONSE
});

export const saveUserDetails = userDetails => ({
  type: SAVE_USER_DETAILS,
  userDetails
});

export const toggleRmaValidation = () => ({
  type: TOGGLE_RMA_VALIDATION
});

export const startReturnMerchandiseAuthorization = (
  extReferenceNumber,
  fromAddress,
  toAddress,
  userId,
  proceedWithExistingExternalReferenceNumber,
  yes,
  no
) => async dispatch => {
  try {
      const postData = {
      extReferenceNumber: extReferenceNumber,
      fromAddress: fromAddress,
      toAddress: toAddress,
      serviceAdministratorId: SERVICE_ADMINISTRATOR_ID,
      serviceProviderIdFrom: userInfo.companyId,
      serviceProviderLocationIdFrom: fromAddress.serviceProviderLocationId,
      serviceProviderIdTo: "",
      serviceProviderLocationIdTo: "",
      returnType: "",
      returnReason: "",
      returnStatus: "",
      returnNotes: "",
      clientOwningSystemCode: CLIENT_OWNING_SYSTEM_CODE,
      createdBy: userId,
      createdDate: "",
      lastModifiedBy: userId,
      lastModifiedDate: "",
      proceedWithExistingReferenceNumExt: proceedWithExistingExternalReferenceNumber
    };
    console.log(postData);
    const response = await Post(uriName, `/v1/rma`, postData);
    console.log(response);
    if(hasError(response)){
      dispatch(showErrorMessageET('ERROR IN RESPONSE. RMA INITIATION FAILED!'));
    } else if (response && !isEmpty(response)){
      if(response.inventoryRtnId !== undefined && proceedWithExistingExternalReferenceNumber === false && response.message === "Insertion success") {
        console.log(response.inventoryRtnId);
        dispatch({ type: SAVE_RMA_ACTION});
        dispatch(saveInventoryRtnId(response));
        dispatch(hideRMASection());
      }
      if (response && response.inventoryRtnId !== undefined && proceedWithExistingExternalReferenceNumber === true && response.message === "Insertion success") {
        console.log(response.inventoryRtnId);
        dispatch({ type: SAVE_RMA_ACTION});
        dispatch(saveInventoryRtnId(response));
        dispatch(closeRmaModal());
        dispatch(hideRMASection());
      }
      if (response && response !== undefined && response.message === "External Reference Number already exists") {
        dispatch(showRmaAlreadyExistsValidation());
      }
    }
     
  } catch (err) {
    if (hasError(err)) {
      dispatch(showErrorMessageET('RMA INITIATION FAILED!'));
    }
  }
};

export const onModelCancel =()=> async dispatch =>{
  dispatch(closeRmaModal());
}

export const hideDeleteCapability = () => ({
  type: HIDE_DELETE_CAPABILITY
});

export const closeRmaModal = () => ({
  type: CLOSE_RMA_MODAL
});

export const hideRMASection = () => ({
  type: HIDE_RMA_SECTION
});

export const deletePartSerial = (
  serialNumber,
  partDetailsExternalTransfers,
  inventoryRtnId
) => async dispatch => {
  try {
    console.log(`deletion in actual method`)
    const postData = {
      partSerialNumber: serialNumber,
      inventoryRtnId: inventoryRtnId,
      deleteSerial: true,
      rmaStatusChange: false
    };
    const response = await Post(uriName, `/v1/rmaserial`, postData);
    console.log(response)
    if (hasError(response)) {
      dispatch(showErrorMessage("Unable to delete the serial."));
    } else if (response && !isEmpty(response)) {
      partDetailsExternalTransfers=partDetailsExternalTransfers.filter((det)=>(det.serialNumber!==serialNumber)?true:false)
      dispatch({type:UPDATE_PARTS_DETAILS,partDetailsExternalTransfers:partDetailsExternalTransfers});
    }
  } catch (error) {
    console.log(error)
    if (hasError(error)) {
      dispatch(showErrorMessage("Cannot delete. Please try again!"));
    }
  }
};

export const deleteSelectedPartSerial = index => ({
  type: DELETE_SELECTED_PART,
  index
});

export const saveServiceProviderIdExternalTransfer = companyId => ({
  type: SAVE_SERVICE_PROVIDER_ID_EXTERNAL_TRANSFERS,
  companyId
});

export const saveInventoryRtnId = response => ({
  type: SAVE_INVENTORY_RTN_ID,
  response
});

export const saveSelectedFromAddressDetails = fromAddress => async dispatch => {
  dispatch(
    saveServiceProviderLocationIdExternalTransfer(
      `${fromAddress.serviceProviderLocationId}`
    )
  );
  dispatch(saveSelectedFromAddress(fromAddress));
};

export const saveSelectedFromAddress = fromAddress => ({
  type: SAVE_SELECTED_FROM_ADDRESS,
  fromAddress
});

export const saveServiceProviderLocationIdExternalTransfer = spLocId => ({ type: SAVE_SERVICE_PROVIDER_LOCATION_ID_EXTERNAL_TRANSFERS,spLocId});

export const showRmaAlreadyExistsValidation = () => ({
  type: RMA_ALREADY_EXISTS
});

export const saveDummyDestinationDetails = response => ({
  type: SAVE_DUMMY_DESTINATION_DETAILS,
  response
});

export const saveUserDefaultLocationAddressDetails = response => ({
  type: SAVE_USER_DEFAULT_LOCATION_ADDRESS_DETAILS,
  response
});

export const saveDummyPartDetails = partDummyDetails => ({
  type: SAVE_DUMMY_PART_DETAILS_EXTERNAL_TRANSFERS,
  partDummyDetails
});

export const enableExternalTransfers = () => ({
  type: ENABLE_EXTERNAL_TRANSFERS
});


export const clearAllData = () => ({
  type: CLEAR_ALL_DATA
})

export const disableExternalTransfers = () => ({
  type: DISABLE_EXTERNAL_TRANSFERS
});

export const movePart = () => async dispatch => {
  dispatch(setExternalTransferComplete());
};

export const setExternalTransferComplete = () => ({
  type: SET_EXTERNAL_TRANSFER_COMPLETE
});

export const saveSelectedToAddress = toAddress => ({
  type: SAVE_SELECTED_TO_ADDRESS,
  toAddress
});

export const getExternalTransfersForSelectedRange = (fromDate, toDate) => ({
  type: EXTERNAL_TRANSFERS_FOR_SELECTED_RANGE
});

export const navigateToPreviousExternalTransfersFromExternalTransfersDashboard = () => ({
  type: NAVIGATE_TO_HISTORIC_EXTERNAL_TRANSFERS
});

export const startNewExternalTransfer = () => ({
  type: START_NEW_EXTERNAL_TRANSFER
});

export const populateRMADetails = rmaDetails => ({
  type: POPULATE_RMA_DETAILS,
  rmaDetails
});

export const navigateExternalTransfers = (extTransferActions, inventoryRtnId) => async dispatch => {
  await dispatch(navigateET());
  await dispatch(updateInventoryRtnId(inventoryRtnId));
  await dispatch(saveReferenceDetails(extTransferActions, inventoryRtnId));
}

export const navigateET = () => ({
  type: NAVIGATE_TO_EXTERNAL_TRANSFERS
});

export const saveReferenceDetails = (
  extTransferActions,
  inventoryRtnId
) => async dispatch => {
  try {
    let referenceNumber = null;
    let actionAgainstReferenceNumber = null;
    let inventoryId = inventoryRtnId;
    Object.keys(extTransferActions).forEach(function(va) {
      referenceNumber = va;
      actionAgainstReferenceNumber = extTransferActions[va];
    });
    const postData = {
      referenceNumber: referenceNumber,
      inventoryRtnId: inventoryId
    };
    const response = await Post(uriName, `/v1/rmatransfers`, postData);
    console.log(response)
    await dispatch(saveRMADetails(response));
    await dispatch(populateRMARelatedAddressDetails(response));
  } catch (err) {
    console.log(err);
  }
};

export const populateRMARelatedAddressDetails = response => ({
  type: POPULATE_RMA_RELATED_ADDRESS_DETAILS,
  response
});

export const saveRMADetails = response => ({
  type: SAVE_RMA_RELATED_DETAILS,
  response
});

export const disableScan = () => ({
  type: DISABLE_SCANNING_ABILITY
});


export const completeTransfer = (inventoryRtnId)=>async dispatch =>{
  try {
    const postData = {
      rmaStatusChange: true,
      rmaStatus: 'COMPLETED',
      deleteSerial: false,
      inventoryRtnId: inventoryRtnId
    };
    const response = await Post(uriName, `/v1/rmaserial`, postData);
    console.log(response);
    return response;
  } catch (err) {
    console.log(err);
  }
}

export const printPackingDetails = inventoryRtnId => async dispatch => {
  try {
    const postData = {
      rmaStatusChange: true,
      rmaStatus: 'PKNG_LST_CREATED',
      deleteSerial: false,
      inventoryRtnId: inventoryRtnId
    };
    const response = await Post(uriName, `/v1/rmaserial`, postData);
    console.log(response);
    dispatch({type:UPDATE_RMA_ACTION, status:'PKNG_LST_CREATED'});
  } catch (err) {
    console.log(err);
  }
};

export const clearPerformAction = () => async dispatch => {
dispatch({type:CLEAR_PERFORM})
};

const partExists = async (partDetails,serialNumber)=>{
  for(let acceptedPart of partDetails){
    if(acceptedPart.serialNumber===serialNumber){
      return true;
    }
  }
return false;
}